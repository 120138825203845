import React, { Component } from "react";
import SliderComponentVCPULxc from "../SliderComponentVCPULxc";
import SliderComponentRamLxc from "../SliderComponentRamLxc";
import SliderComponentDisk from "../SliderComponentDisk";
import { withTranslation } from "react-i18next";

import Accordion from "../Accordion";
import LxcCalc from "../LxcCalc";

import { PRODUCT_TYPES } from "../../const/products";

import "./../../css/vps.scss";

import bck1_xxl from "../../assets/img/bb.png";
import bck1_xl from "../../assets/img/bb.png";
import bck1_l from "../../assets/img/bb.png";
import bck1_md from "../../assets/img/bb.png";
import bck1_sm from "../../assets/img/bb.png";

let tooltip1 =
  "<strong style='color:black;'>Samostojni način </strong> (privzeta vrednost) je običajni način delovanja, kjer okvara našega fizičnega strežnika vpliva na prisotnost vašega virtualnega strežnika.<br/><br/><strong style='color:black;'> Replikacija</strong> je način delovanja v katerem se v določenih časovnih intervalih spremembe iz enega fizičnega strežnika replicirajo na drugega. Ob izpadu prvega se z minilano izgubo podatkov od zadnje replikacije zažene strežnik iz replikacije.<br/><br/><strong style='color:black;'>High avialibility</strong> je način v katerem se za hranjenje podatkov uporablja diskovno polje ali drug distribuiran način hrambe podatkov.<br />Po izpadu primarnega fizičnega strežnika, se Vaš virtualni strežnik zažene na drugem fizičnem strežniku brez izgube podatkov.";
let tooltip2 =
  "<strong style='color:black;'>Shared CPU</strong>: Vsota vCPU jeder vseh virtualnih strežnikov je večja od števila CPU jeder fizičnega strežnika.<br />Obremenitev procesorja ne presega 50% v intervalu 15 minut.<br /><br /><strong style='color:black;'>Dedicated CPU</strong>: Vsota vCPU jeder vseh virtualnih strežnikov je manjša ali enaka številu CPU jeder v fizičnem strežniku.<br />Neomejena obremenitev procesorja.<br /><br /><strong style='color:black;'>High Speed CPU</strong>: Nadgradnja Dedicated CPU.<br />Fizični strežnik z manj CPU jedri, vendar hitrejšimi.<br />Primerno za programsko opremo, kjer je licenčnina vezana na število CPU jeder.";
let tooltip4 =
  "Lokalni disk je primeren za samostojno delovanje in replikacijo, saj se hramba podatkov nahaja lokalno v strežniku.<br />Deljeni disk je primeren za način delovanja High Availability saj je podatkovna shramba deljena med strežniki in tako dostopna na večih strežnikih istočasno.";

let isSmall, isMedium, isLarge, isXLarge, isXXLarge;

class LxcT extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nacinDelovanja: "common.cpu.standAlone.name",
      nacin: "common.cpu.sharedCPU.name",
      kolicinaCPU: "1",
      kolicinaRam: "1",
      primarniTipDiska: "common.disk.localSsd",
      kolicinaDisk: "16",
      steviloJavnihIP: "0",
      steviloMreznihVmesnikov: "1",
      os: "Ubuntu Server 20.04 LTS",
      highAvailability: false,
      deljeniSSD: true,
      activeKey: ["1"],
      obdobjeNajema: "common.finance.subscription.monthlyNoDiscount",
      priceMonthly: "1.64",
      priceYearly: "19.65",
      priceStartup: "0.00",
      priceFirstPayment: "1.64",
      isSmall: window.innerWidth < 480,
      isMedium: window.innerWidth > 481 && window.innerWidth <= 768,
      isLarge: window.innerWidth > 769 && window.innerWidth <= 1499,
      isXLarge: window.innerWidth > 1500 && window.innerWidth <= 2800,
      isXXLarge: window.innerWidth > 2801 && window.innerWidth < 3500,

      calcType: PRODUCT_TYPES.LXC,
    };

    isSmall = window.innerWidth < 480;
    isMedium = window.innerWidth > 481 && window.innerWidth <= 768;
    isLarge = window.innerWidth > 769 && window.innerWidth <= 1499;
    isXLarge = window.innerWidth > 1500 && window.innerWidth <= 2800;
    isXXLarge = window.innerWidth > 2801 && window.innerWidth < 3500;

    this.setSliderValVCPU = this.setSliderValVCPU.bind(this);
    this.setSliderValRam = this.setSliderValRam.bind(this);
    this.setSliderValDisk = this.setSliderValDisk.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (isXXLarge) {
      this.setState({ backgroundImage1: bck1_xxl });
    }
    if (isXLarge) {
      this.setState({ backgroundImage1: bck1_xl });
    }
    if (isLarge) {
      this.setState({ backgroundImage1: bck1_l });
    }
    if (isMedium) {
      this.setState({ backgroundImage1: bck1_md });
    }
    if (isSmall) {
      this.setState({ backgroundImage1: bck1_sm });
    }
    window.scrollTo(0, 0);
  }

  onChangeCollapse = (activeKey) => {
    this.setState({
      activeKey,
    });
  };

  handleChange = (evt) => {
    let highAvailabilityChecked = this.state.highAvailability;
    let deljeniSSDChecked = this.state.deljeniSSD;
    let primarniTipDiskaSelected;
    if (evt.target.name === "nacinDelovanja") {
      if (evt.target.value === "common.cpu.highAvailability.name") {
        highAvailabilityChecked = true;
        deljeniSSDChecked = false;
        primarniTipDiskaSelected = "common.disk.sharedCephSsd";
      }
      if (
        evt.target.value === "common.cpu.standAlone.name" ||
        evt.target.value === "common.cpu.replication.name"
      ) {
        if (this.state.primarniTipDiska === "common.disk.sharedCephSsd") {
          primarniTipDiskaSelected = "common.disk.localSsd";
        } else {
          primarniTipDiskaSelected = this.state.primarniTipDiska;
        }
        highAvailabilityChecked = false;
        deljeniSSDChecked = true;
      }
    } else if (evt.target.name === "primarniTipDiska") {
      if (evt.target.value === "") {
        if (
          this.state["nacinDelovanja"] === "common.cpu.highAvailability.name"
        ) {
          primarniTipDiskaSelected = "common.disk.sharedCephSsd";
        } else {
          primarniTipDiskaSelected = "common.disk.localNvme";
        }
      } else {
        primarniTipDiskaSelected = evt.target.value;
      }
    } else {
      primarniTipDiskaSelected = this.state.primarniTipDiska;
    }

    const value1 =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;

    this.setState(
      {
        ...this.state,
        [evt.target.name]: value1,
        highAvailability: highAvailabilityChecked,
        deljeniSSD: deljeniSSDChecked,
        // ["primarniTipDiska"]: primarniTipDiskaSelected ?? ""
      },
      function () {
        this.calcuatePrice();
      }
    );
  };
  calcuatePrice() {
    let nacinDelovanja = this.state.nacinDelovanja;
    let nacinSredstev = this.state.nacin;
    let kolicinaCPU = this.state.kolicinaCPU;
    let kolicinaRam = this.state.kolicinaRam;
    let primarniTipDiska = this.state.primarniTipDiska;
    let kolicinaDisk = this.state.kolicinaDisk;
    let steviloJavnihIP = this.state.steviloJavnihIP;
    //  let steviloMreznihVmesnikov = this.state.steviloMreznihVmesnikov;
    let obdobjeNajema = this.state.obdobjeNajema;

    let priceMonthly = 0;
    let priceYearDiscount = 0.06;
    let priceYearly = 0;
    let priceStartup = 0;
    let priceFirstPayment = 0;

    let priceBaseStandalone = 0.49;
    let priceBaseReplication = 1.74;

    let priceSharedVCpuStandAlone = 0.88;
    let priceSharedVCpuReplication = 1.14;
    let priceDedicatedVCpuStandAlone = 2.93;
    let priceDedicatedVCpuReplication = 3.81;
    let priceTurboVCpuStandAlone = 12.46;
    let priceTurboVCpuReplication = 16.19;

    let priceRamStandAloneShared = 0.12;
    let priceRamStandAloneDedicated = 0.16;
    let priceRamStandAloneTurboCpu = 0.18;
    let priceRamReplicationShared = 0.16;
    let priceRamReplicationDedicated = 0.23;
    let priceRamReplicationTurboCpu = 0.25;

    let priceDiskLocalNvme = 0.06;
    let priceDiskLocalSsd = 0.02;
    // let priceDiskBackupBox = 0.013;

    let pricePublicIPv4 = 0.98;
    // let pricePublicIPv6 = 0.32;

    switch (nacinDelovanja) {
      case "common.cpu.standAlone.name":
        priceMonthly = priceBaseStandalone;
        break;
      case "common.cpu.replication.name":
        priceMonthly = priceBaseReplication;
        break;
      default:
        priceMonthly = 0;
    }

    switch (nacinSredstev) {
      case "common.cpu.sharedCPU.name":
        switch (nacinDelovanja) {
          case "Samostojni način":
            priceMonthly += kolicinaCPU * priceSharedVCpuStandAlone;
            priceMonthly += kolicinaRam * priceRamStandAloneShared;
            break;
          case "common.cpu.replication.name":
            priceMonthly += kolicinaCPU * priceSharedVCpuReplication;
            priceMonthly += kolicinaRam * priceRamReplicationShared;
            break;
          default:
            priceMonthly = 0;
        }
        break;
      case "common.cpu.dedicatedCPU.name":
        switch (nacinDelovanja) {
          case "common.cpu.standAlone.name":
            priceMonthly += kolicinaCPU * priceDedicatedVCpuStandAlone;
            priceMonthly += kolicinaRam * priceRamStandAloneDedicated;
            break;
          case "common.cpu.replication.name":
            priceMonthly += kolicinaCPU * priceDedicatedVCpuReplication;
            priceMonthly += kolicinaRam * priceRamReplicationDedicated;
            break;
          default:
            priceMonthly = 0;
        }
        break;
      case "common.cpu.highSpeedCPU.name":
        switch (nacinDelovanja) {
          case "common.cpu.standAlone.name":
            priceMonthly += kolicinaCPU * priceTurboVCpuStandAlone;
            priceMonthly += kolicinaRam * priceRamStandAloneTurboCpu;
            break;
          case "common.cpu.replication.name":
            priceMonthly += kolicinaCPU * priceTurboVCpuReplication;
            priceMonthly += kolicinaRam * priceRamReplicationTurboCpu;
            break;
          default:
            priceMonthly = 0;
        }
        break;
      default:
        priceMonthly = 0;
    }

    switch (primarniTipDiska) {
      case "common.disk.localNvme":
        priceMonthly += kolicinaDisk * priceDiskLocalNvme;
        break;
      case "common.disk.localSsd":
        priceMonthly += kolicinaDisk * priceDiskLocalSsd;
        break;
      default:
        priceMonthly = 0;
    }

    priceMonthly += steviloJavnihIP * pricePublicIPv4;

    priceYearly = priceMonthly * 12;

    switch (obdobjeNajema) {
      case "common.finance.subscription.monthlyNoDiscount":
        priceFirstPayment = priceStartup + priceMonthly;
        break;
      case "common.finance.subscription.yearlyDiscount":
        priceMonthly *= 1 - priceYearDiscount;
        priceYearly *= 1 - priceYearDiscount;
        priceFirstPayment = priceStartup + priceYearly;
        break;
      default:
        priceMonthly = 0;
        priceYearly = 0;
    }

    priceYearly = priceYearly.toFixed(2);
    priceMonthly = priceMonthly.toFixed(2);
    priceStartup = priceStartup.toFixed(2);
    priceFirstPayment = priceFirstPayment.toFixed(2);

    this.setState({
      ...this.state,
      priceMonthly: priceMonthly,
      priceYearly: priceYearly,
      priceStartup: priceStartup,
      priceFirstPayment: priceFirstPayment,
    });
  }

  setSliderValVCPU(val) {
    this.setState({
      ...this.state,
      kolicinaCPU: val.size,
    });

    if (val.size >= 2 && val.size > this.state.kolicinaRam) {
      this.setState({
        ...this.state,
        kolicinaRam: val.size,
      });
    }
    this.calcuatePrice();
  }

  setSliderValRam(val) {
    this.setState({
      ...this.state,
      kolicinaRam: val.size,
    });
    this.calcuatePrice();
  }

  setSliderValDisk(val) {
    this.setState({
      ...this.state,
      kolicinaDisk: val.size,
    });
    this.calcuatePrice();
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="panel-div vps-panel">
          <div
            className="subpanel-div vps-subpanel"
            style={{
              backgroundImage: `url(${this.state.backgroundImage1})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="vps-inner-div">
              <div className="vps-box">
                <div className="vps-box-text">
                  <div className="vps-box-text-1">
                    <h1 className="vps-inner-title">{t("lxc.title")}</h1>
                    <div className="vps-li first">
                      {t("lxc.text1")}
                      <br />
                      {t("lxc.text2")}
                    </div>
                    <hr />
                    <div className="vps-mail">
                      {t("lxc.question1")}
                      <br />
                      {t("lxc.question2")}
                      <a href="mailto:sales@reavisys.si"> sales@reavisys.si</a>
                    </div>
                  </div>
                  <div className="vps-box-text-2">
                    <div className="vps-li">{t("lxc.line1")}</div>
                    <div className="vps-li">{t("lxc.line2")}</div>
                    <div className="vps-li">{t("lxc.line3")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="panel-div vps-panel-1">
          <div className="subpanel-div vps-subpanel-1">
            <div className="vps-description">
              <p>{t("common.title")}</p>
            </div>
            <div className="vps-div">
              <div className="vps-div-calc">
                <div className="vps-item" data-for="tip-1">
                  <div className="vps-item-title">
                    {t("common.cpu.operationType")}
                  </div>
                  <div>
                    <label>
                      <input
                        type="radio"
                        name="nacinDelovanja"
                        value="common.cpu.standAlone.name"
                        checked={
                          this.state.nacinDelovanja ===
                          "common.cpu.standAlone.name"
                        }
                        onChange={this.handleChange}
                      />
                      {t("common.cpu.standAlone.name")}
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="radio"
                        name="nacinDelovanja"
                        value="common.cpu.replication.name"
                        checked={
                          this.state.nacinDelovanja ===
                          "common.cpu.replication.name"
                        }
                        onChange={this.handleChange}
                      />
                      {t("common.cpu.replication.name")}
                    </label>
                  </div>
                  <Accordion key={1} keyItem={1} keyText={"common.cpu.completeTextLxc1"} />
                </div>
                <hr className="vps-hr" />
                <br />
                <div className="vps-item" data-for="tip-3">
                  <div>
                    <div className="vps-item-title">
                      {t("common.cpu.resourceAllocation")}
                    </div>
                    <div>
                      <label>
                        <input
                          type="radio"
                          name="nacin"
                          value="common.cpu.sharedCPU.name"
                          checked={
                            this.state.nacin === "common.cpu.sharedCPU.name"
                          }
                          onChange={this.handleChange}
                        />
                        {t("common.cpu.sharedCPU.name")}
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          type="radio"
                          name="nacin"
                          value="common.cpu.dedicatedCPU.name"
                          checked={
                            this.state.nacin === "common.cpu.dedicatedCPU.name"
                          }
                          onChange={this.handleChange}
                        />
                        {t("common.cpu.dedicatedCPU.name")}
                      </label>
                    </div>
                    <div>
                      <label>
                        <input
                          type="radio"
                          name="nacin"
                          value="common.cpu.highSpeedCPU.name"
                          checked={
                            this.state.nacin === "common.cpu.highSpeedCPU.name"
                          }
                          onChange={this.handleChange}
                        />
                        {t("common.cpu.highSpeedCPU.name")}
                      </label>
                    </div>
                  </div>
                  <Accordion keyItem={2} keyText={"common.cpu.completeText2"} />
                </div>
                <hr className="vps-hr" />
                <div className="vps-item">
                  <SliderComponentVCPULxc
                    onChangeSliderValVCPULxc={this.setSliderValVCPU}
                    updateSlider={this.setSliderValRam}
                  />
                </div>
                <hr className="vps-hr" />
                <div className="vps-item">
                  <SliderComponentRamLxc
                    sliderValRam={this.state.kolicinaRam}
                    onChangeSliderValRam={this.setSliderValRam}
                  />
                </div>
                <hr className="vps-hr" />
                <br />

                <div className="vps-item-title">{t("common.disk.title")}</div>
                <p>{this.state.highAvailability}</p>
                <div>
                  <label>
                    <input
                      type="radio"
                      name="primarniTipDiska"
                      value="common.disk.localNvme"
                      checked={
                        this.state.primarniTipDiska === "common.disk.localNvme"
                      }
                      disabled={this.state.highAvailability}
                      onChange={this.handleChange}
                    />
                    {t("common.disk.localNvme")}
                  </label>
                </div>
                <div>
                  <label>
                    <input
                      type="radio"
                      name="primarniTipDiska"
                      value="common.disk.localSsd"
                      checked={
                        this.state.primarniTipDiska === "common.disk.localSsd"
                      }
                      disabled={this.state.highAvailability}
                      onChange={this.handleChange}
                    />
                    {t("common.disk.localSsd")}
                  </label>
                </div>
                <Accordion key={4} keyItem={4} keyText={"common.cpu.completeText3"} />

                <br />
                <hr className="vps-hr" />
                <div className="vps-item">
                  <SliderComponentDisk
                    onChangeSliderValDisk={this.setSliderValDisk}
                  />
                </div>
                <hr className="vps-hr" />
                <br />
                <div className="vps-item">
                  <label>
                    <div className="vps-item-title">
                      {t("common.network.IPv4Count")}
                    </div>
                    <select
                      name="steviloJavnihIP"
                      className="order-input"
                      onChange={this.handleChange}
                      value={this.state.technology}
                    >
                      <option value="0">0</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                  </label>
                  <br />
                  {t("common.network.description")}
                  <br />
                  {t("common.network.IPv6Included")}
                </div>
                <hr className="vps-hr" />
                <br />
                <div className="vps-item">
                  <label>
                    <div className="vps-item-title">
                      {t("common.network.nicCount")}
                    </div>
                    <select
                      name="steviloMreznihVmesnikov"
                      className="order-input"
                      onChange={this.handleChange}
                      value={this.state.technology}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                    </select>
                  </label>
                </div>
                <hr className="vps-hr" />
                <br />
                <div className="vps-item">
                  <label>
                    <div className="vps-item-title">{t("common.os.name")}</div>
                    <select
                      name="os"
                      className="order-input"
                      onChange={this.handleChange}
                    >
                      <option value="Ubuntu Server 20.04 LTS">
                        Ubuntu Server 20.04 LTS
                      </option>
                      <option value="CentOS 7">CentOS 7</option>
                      <option value="CentOS 8 Stream">CentOS 8 - Stream</option>
                      <option value="CentOS 8">CentOS 8</option>
                      <option value="Debian 9">Debian 9</option>
                      <option value="Debian 10">Debian 10</option>
                      <option value="Arch Linux">Arch Linux</option>
                      <option value="OpenSUSE">OpenSUSE</option>
                      <option value="CloudLinux">CloudLinux</option>
                      <option value="CloudLinux + KernelCare">
                        CloudLinux + KernelCare
                      </option>
                      {/*<option value="windowsServerStandard">Windows Server Standard</option>
                                            <option value="windowsServerDatacenter">Windows Server DataCenter</option>*/}
                    </select>
                  </label>
                </div>
                <hr className="vps-hr" />
                <br />
                <div className="vps-item">
                  <label>
                    <div className="vps-item-title">
                      {t("common.finance.subscription.period")}
                    </div>
                    <select
                      name="obdobjeNajema"
                      className="order-input"
                      onChange={this.handleChange}
                    >
                      <option value="common.finance.subscription.monthlyNoDiscount">
                        {t("common.finance.subscription.monthlyNoDiscount")}
                      </option>
                      <option value="common.finance.subscription.yearlyDiscount">
                        {t("common.finance.subscription.yearlyDiscount")}
                      </option>
                    </select>
                  </label>
                </div>
                <hr className="vps-hr" />
                <br />
                <div className="vps-item">
                  {t("common.network.freeInputTraffic")}
                  <br />
                  {t("common.network.freeInternalTraffic")}
                  <br />
                  {t("common.network.includedTraffic")}
                </div>
              </div>
              <LxcCalc className="calc-div" {...this.state} />
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <div className="middle-subtitle middle-1">
            {t("common.priceList.lxc.title")}
          </div>
          <br />
          <div className="vps-table">
            <table>
              <thead>
                <tr>
                  <th>{t("common.priceList.common.service")}</th>
                  <th>{t("common.finance.subscription.monthly")}</th>
                  <th>{t("common.finance.subscription.setup")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t("common.priceList.common.baseStandAlone")}</td>
                  <td>0,49 €</td>
                  <td>{t("common.priceList.common.free")}</td>
                </tr>
                <tr>
                  <td>{t("common.priceList.common.baseReplication")}</td>
                  <td>1,74 €</td>
                  <td>{t("common.priceList.common.free")}</td>
                </tr>
                <tr>
                  <td>{t("common.priceList.common.vCpuSharedStandAlone")}</td>
                  <td>0,88 €</td>
                  <td>{t("common.priceList.common.free")}</td>
                </tr>
                <tr>
                  <td>{t("common.priceList.common.vCpuSharedReplication")}</td>
                  <td>1,14 €</td>
                  <td>{t("common.priceList.common.free")}</td>
                </tr>
                <tr>
                  <td>
                    {t("common.priceList.common.vCpuDedicatedStandAlone")}
                  </td>
                  <td>2,93 €</td>
                  <td>{t("common.priceList.common.free")}</td>
                </tr>
                <tr>
                  <td>
                    {t("common.priceList.common.vCpuDedicatedReplication")}
                  </td>
                  <td>3,81 €</td>
                  <td>{t("common.priceList.common.free")}</td>
                </tr>
                <tr>
                  <td>{t("common.priceList.common.vCpuTurboStandAlone")}</td>
                  <td>12,46 €</td>
                  <td>{t("common.priceList.common.free")}</td>
                </tr>
                <tr>
                  <td>{t("common.priceList.common.vCpuTurboReplication")}</td>
                  <td>16,19 €</td>
                  <td>{t("common.priceList.common.free")}</td>
                </tr>
                <tr>
                  <td>{t("common.priceList.common.ramStandAloneShared")}</td>
                  <td>0,12 €</td>
                  <td>{t("common.priceList.common.free")}</td>
                </tr>
                <tr>
                  <td>{t("common.priceList.common.ramStandAloneDedicated")}</td>
                  <td>0,16 €</td>
                  <td>{t("common.priceList.common.free")}</td>
                </tr>
                <tr>
                  <td>{t("common.priceList.common.ramStandAloneTurbo")}</td>
                  <td>0,18 €</td>
                  <td>{t("common.priceList.common.free")}</td>
                </tr>
                <tr>
                  <td>{t("common.priceList.common.ramReplicationShared")}</td>
                  <td>0,16 €</td>
                  <td>{t("common.priceList.common.free")}</td>
                </tr>
                <tr>
                  <td>
                    {t("common.priceList.common.ramReplicationDedicated")}
                  </td>
                  <td>0,23 €</td>
                  <td>{t("common.priceList.common.free")}</td>
                </tr>
                <tr>
                  <td>{t("common.priceList.common.ramReplicationTurbo")}</td>
                  <td>0,25 €</td>
                  <td>{t("common.priceList.common.free")}</td>
                </tr>
                <tr>
                  <td>{t("common.priceList.common.diskLocalSsd")}</td>
                  <td>0,02 €</td>
                  <td>{t("common.priceList.common.free")}</td>
                </tr>
                <tr>
                  <td>{t("common.priceList.common.diskLocalSsdNvme")}</td>
                  <td>0,06 €</td>
                  <td>{t("common.priceList.common.free")}</td>
                </tr>
                <tr>
                  <td>{t("common.priceList.common.networkOutputTraffic")}</td>
                  <td>0,98 €</td>
                  <td>{t("common.priceList.common.free")}</td>
                </tr>
                <tr>
                  <td>{t("common.priceList.common.networkIPv4")}</td>
                  <td>0,98 €</td>
                  <td>{t("common.priceList.common.free")}</td>
                </tr>
                <tr>
                  <td>{t("common.priceList.common.networkIPv6")}</td>
                  <td>0,32 €</td>
                  <td>{t("common.priceList.common.free")}</td>
                </tr>
                <tr>
                  <td>{t("common.priceList.common.backupBox")}</td>
                  <td>
                    <a href="/backup-box">
                      {t("common.priceList.common.backupBoxLink")}
                    </a>
                  </td>
                  <td>
                    <a href="/backup-box">
                      {t("common.priceList.common.backupBoxLink")}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>{t("common.priceList.common.individualOffer")}</td>
                  <td>-</td>
                  <td>23,64 €</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <br />
        </div>
      </>
    );
  }
}
const Lxc = withTranslation()(LxcT);

export default Lxc;
