import React, { Component } from "react"
import { PRODUCT_TYPES } from "../const/products"
import { withTranslation } from "react-i18next";


/** class for collecting data in order form, injected by SendOrderBackupProduct */
class SendOrderUserChoiceT extends Component {

    constructor(props) {
        super(props)
        this.state = {}
        this.state.userChoice = { ...props }
        this.state.calcType = props.calcType
/*         this.childOrderSuccess = React.createRef(); */
    }

    render() {
        const { t } = this.props;
        this.state.userChoice = { ...this.props }
        console.log("this.props.userChoice.calcType> ", this.props.userChoice.calcType)
        return (

            <div className="order-form">
                <h2 className="order-form-title">{t('order.currentCalculation.title')}</h2>

                {(this.props.userChoice.calcType === PRODUCT_TYPES.QNAP_BACKUP || (this.props.userChoice.calcType === PRODUCT_TYPES.BACKUP_BOX || this.props.userChoice.calcType === PRODUCT_TYPES.SYNOLOGY_BACKUP)) &&
                    <div>
                        <div>{t('order.currentCalculation.dataSpace')}: <div className="send-order-choice"> {this.props.userChoice.backupSize}</div></div>
                        <div>{t('order.currentCalculation.subscriptionPeriod')}: <div className="send-order-choice"> {t(this.props.userChoice.obdobjeNajema)}</div></div>

                        {(this.props.userChoice.obdobjeNajema === "common.finance.subscription.monthlyNoDiscount") &&
                            <>
                                <div>{t('order.currentCalculation.firstPayment')}: <div className="send-order-choice"> {this.props.userChoice.priceFirstPayment} €</div></div>
                                <div>{t('order.currentCalculation.nextPayment')}: <div className="send-order-choice"> {this.props.userChoice.priceMonthly} €</div></div>
                            </>
                        }
                        {(this.props.userChoice.obdobjeNajema === "common.finance.subscription.yearlyDiscount") &&
                            <>
                                <div>{t('order.currentCalculation.firstPayment')}: <div className="send-order-choice"> {this.props.userChoice.priceFirstPayment} €</div></div>
                                <div>{t('order.currentCalculation.nextPayment')}: <div className="send-order-choice"> {this.props.userChoice.priceYearly} €</div></div>
                            </>
                        }
                        <br />
                    </div>
                }
                {(this.props.userChoice.calcType === PRODUCT_TYPES.VPS) &&
                    <div>
                        <div>{t('order.currentCalculation.operationMode')}: <div className="send-order-choice"> {t(this.props.userChoice.nacinDelovanja)}</div></div>
                        <div>{t('order.currentCalculation.resourceAllocation')}: <div className="send-order-choice"> {t(this.props.userChoice.nacin)}</div></div>
                        <div>{t('order.currentCalculation.vCpuCount')}: <div className="send-order-choice"> {this.props.userChoice.kolicinaCPU} vCPU</div></div>
                        <div>{t('order.currentCalculation.ramSize')}: <div className="send-order-choice"> {this.props.userChoice.kolicinaRam} GB</div></div>
                        <div>{t('order.currentCalculation.primaryDiskType')}: <div className="send-order-choice"> {t(this.props.userChoice.primarniTipDiska)}</div></div>
                        <div>{t('order.currentCalculation.primaryDiskSize')}: <div className="send-order-choice"> {this.props.userChoice.kolicinaDisk} GB</div></div>
                        <div>{t('order.currentCalculation.netIPv4Count')}: <div className="send-order-choice"> {this.props.userChoice.steviloJavnihIP}</div></div>
                        <div>{t('order.currentCalculation.netInterfaces')}: <div className="send-order-choice"> {this.props.userChoice.steviloMreznihVmesnikov}</div></div>
                        <div>{t('order.currentCalculation.os')}: <div className="send-order-choice"> {t(this.props.userChoice.os)}</div></div>
                        <div>{t('order.currentCalculation.controlPanel')}: <div className="send-order-choice"> {t(this.props.userChoice.nadzornaPlosca)}</div></div>
                        <div>{t('order.currentCalculation.numberOfUsbKeys')}: <div className="send-order-choice"> {this.props.userChoice.lastenUSB}</div></div>
                        <div>{t('order.currentCalculation.subscriptionPeriod')}: <div className="send-order-choice"> {t(this.props.userChoice.obdobjeNajema)}</div></div>
                        {(this.props.userChoice.obdobjeNajema === "common.finance.subscription.monthlyNoDiscount") &&
                            <>
                                <div>{t('order.currentCalculation.firstPayment')}: <div className="send-order-choice"> {this.props.userChoice.priceFirstPayment} €</div></div>
                                <div>{t('order.currentCalculation.nextPayment')}: <div className="send-order-choice"> {this.props.userChoice.priceMonthly} €</div></div>
                            </>
                        }
                        {(this.props.userChoice.obdobjeNajema === "common.finance.subscription.yearlyDiscount") &&
                            <>
                                <div>{t('order.currentCalculation.firstPayment')}: <div className="send-order-choice"> {this.props.userChoice.priceFirstPayment} €</div></div>
                                <div>{t('order.currentCalculation.nextPayment')}: <div className="send-order-choice"> {this.props.userChoice.priceYearly} €</div></div>
                            </>
                        }
                        <br />
                    </div>
                }
                {(this.props.userChoice.calcType === PRODUCT_TYPES.LXC) &&
                    <div>
                        <div>{t('order.currentCalculation.operationMode')}: <div className="send-order-choice"> {t(this.props.userChoice.nacinDelovanja)}</div></div>
                        <div>{t('order.currentCalculation.resourceAllocation')}: <div className="send-order-choice"> {t(this.props.userChoice.nacin)}</div></div>
                        <div>{t('order.currentCalculation.vCpuCount')}: <div className="send-order-choice"> {this.props.userChoice.kolicinaCPU} vCPU</div></div>
                        <div>{t('order.currentCalculation.ramSize')}: <div className="send-order-choice"> {this.props.userChoice.kolicinaRam} GB</div></div>
                        <div>{t('order.currentCalculation.primaryDiskType')}: <div className="send-order-choice"> {t(this.props.userChoice.primarniTipDiska)}</div></div>
                        <div>{t('order.currentCalculation.primaryDiskSize')}: <div className="send-order-choice"> {this.props.userChoice.kolicinaDisk} GB</div></div>
                        <div>{t('order.currentCalculation.netIPv4Count')}: <div className="send-order-choice"> {this.props.userChoice.steviloJavnihIP}</div></div>
                        <div>{t('order.currentCalculation.netInterfaces')}: <div className="send-order-choice"> {this.props.userChoice.steviloMreznihVmesnikov}</div></div>
                        <div>{t('order.currentCalculation.os')}: <div className="send-order-choice"> {t(this.props.userChoice.os)}</div></div>
                        <div>{t('order.currentCalculation.subscriptionPeriod')}: <div className="send-order-choice"> {t(this.props.userChoice.obdobjeNajema)}</div></div>
                        {(this.props.userChoice.obdobjeNajema === "common.finance.subscription.monthlyNoDiscount") &&
                            <>
                                <div>{t('order.currentCalculation.firstPayment')}: <div className="send-order-choice"> {this.props.userChoice.priceFirstPayment} €</div></div>
                                <div>{t('order.currentCalculation.nextPayment')}: <div className="send-order-choice"> {this.props.userChoice.priceMonthly} €</div></div>
                            </>
                        }
                        {(this.props.userChoice.obdobjeNajema === "common.finance.subscription.yearlyDiscount") &&
                            <>
                                <div>{t('order.currentCalculation.firstPayment')}: <div className="send-order-choice"> {this.props.userChoice.priceFirstPayment} €</div></div>
                                <div>{t('order.currentCalculation.nextPayment')}: <div className="send-order-choice"> {this.props.userChoice.priceYearly} €</div></div>
                            </>
                        }
                        <br />
                    </div>
                }
                {(this.props.userChoice.calcType === PRODUCT_TYPES.COLOCATION) &&
                    <div>

                        <div>{t('order.currentCalculation.rackSpace')}: <div className="send-order-choice"> {this.props.userChoice.colocationSize} U</div></div>
                        <div>{t('order.currentCalculation.deviceCount')}: <div className="send-order-choice"> {this.props.userChoice.numberOfServers}</div></div>
                        <div>{t('order.currentCalculation.powerConnection')}: <div className="send-order-choice"> {this.props.userChoice.colocationPower} W</div></div>
                        <div>{t('order.currentCalculation.net1GbpsRJ45')}: <div className="send-order-choice"> {this.props.userChoice.interfaceRJ45_1gbps}</div></div>
                        <div>{t('order.currentCalculation.net10GbpsRJ45')}: <div className="send-order-choice"> {this.props.userChoice.interfaceRJ45_10gbps}</div></div>
                        <div>{t('order.currentCalculation.net10GbpsSFP+')}: <div className="send-order-choice"> {this.props.userChoice.interfaceSFPplus}</div></div>
                        <div>{t('order.currentCalculation.netIPv4Count')}: <div className="send-order-choice"> {this.props.userChoice.addressIPv4}</div></div>
                        <div>{t('order.currentCalculation.netTraffic')}: <div className="send-order-choice"> {this.props.userChoice.traffic} TB</div></div>
                        <div>{t('order.currentCalculation.subscriptionPeriod')}: <div className="send-order-choice"> {t(this.props.userChoice.obdobjeNajema)}</div></div>
                        {(this.props.userChoice.obdobjeNajema === "common.finance.subscription.monthlyNoDiscount") &&
                            <>
                                <div>{t('order.currentCalculation.firstPayment')}: <div className="send-order-choice"> {this.props.userChoice.priceFirstPayment} €</div></div>
                                <div>{t('order.currentCalculation.nextPayment')}: <div className="send-order-choice"> {this.props.userChoice.priceMonthly} €</div></div>
                            </>
                        }
                        {(this.props.userChoice.obdobjeNajema === "common.finance.subscription.yearlyDiscount") &&
                            <>
                                <div>{t('order.currentCalculation.firstPayment')}: <div className="send-order-choice"> {this.props.userChoice.priceFirstPayment} €</div></div>
                                <div>{t('order.currentCalculation.nextPayment')}: <div className="send-order-choice"> {this.props.userChoice.priceYearly} €</div></div>
                            </>
                        }
                        <br />
                    </div>

                }
                {(this.props.userChoice.calcType === PRODUCT_TYPES.DEDICATED) &&
                    <div>

                        <div>{t('order.currentCalculation.selectedServer')}: <div className="send-order-choice"> {t(this.props.userChoice.selectedServer)}</div></div>
                        
                            <div>{t('order.currentCalculation.selectedStorage')}: <div className="send-order-choice"> {t(this.props.userChoice.selectedStorage)}</div></div>
                        {(this.props.userChoice.selectedStorage ==  "dedicated.storage.nfs") &&
                        <div>
                            <div>{t('order.currentCalculation.primaryDiskSize')}: <div className="send-order-choice"> {this.props.userChoice.diskSize} GB</div></div>
                        </div> 
                        }
                        <div>{t('order.currentCalculation.netIPv4Count')}: <div className="send-order-choice"> {this.props.userChoice.steviloJavnihIP}</div></div>
                        <div>{t('order.currentCalculation.os')}: <div className="send-order-choice"> {this.props.userChoice.os}</div></div>
                        <div>{t('order.currentCalculation.subscriptionPeriod')}: <div className="send-order-choice"> {t(this.props.userChoice.obdobjeNajema)}</div></div>
                        {(this.props.userChoice.obdobjeNajema === "common.finance.subscription.monthlyNoDiscount") &&
                            <>
                                <div>{t('order.currentCalculation.firstPayment')}: <div className="send-order-choice"> {this.props.userChoice.priceFirstPayment} €</div></div>
                                <div>{t('order.currentCalculation.nextPayment')}: <div className="send-order-choice"> {this.props.userChoice.priceMonthly} €</div></div>
                            </>
                        }
                        {(this.props.userChoice.obdobjeNajema === "common.finance.subscription.yearlyDiscount") &&
                            <>
                                <div>{t('order.currentCalculation.firstPayment')}: <div className="send-order-choice"> {this.props.userChoice.priceFirstPayment} €</div></div>
                                <div>{t('order.currentCalculation.nextPayment')}: <div className="send-order-choice"> {this.props.userChoice.priceYearly} €</div></div>
                            </>
                        }
                        <br />
                    </div>

                }
            </div>
        )
    }
}
const SendOrderUserChoice = withTranslation()(SendOrderUserChoiceT);

export default SendOrderUserChoice