import React, { Component } from 'react'
import Modal from 'react-modal'
import './../css/send-order.scss'
import closeIcon from "./../assets/icons/close.png"
import { withTranslation } from 'react-i18next';


class OrderSuccess extends Component {

    constructor(props) {
        super(props)
        this.state = ({
            modalIsOpen: false,
        })
        this.openConfirmSuccess = this.openConfirmSuccess.bind(this);
        this.closeConfirmSuccess = this.closeConfirmSuccess.bind(this);
    }

    componentDidMount() {
        this.props.innerRef.current = this;
      }

      componentWillUnmount() {
        this.props.innerRef.current = null;
      }

    closeConfirmSuccess() {
        this.props.closeParent()
        this.setState({ modalIsOpen: false })
    }

    openConfirmSuccess() {
        this.setState({ modalIsOpen: true })
    }

    render() {
        const { t } = this.props;
        return (
           <Modal
                className="modal-order-confirm"
                isOpen={this.state.modalIsOpen}
                onRequestClose={this.closeConfirmSuccess}
                contentLabel=""
                ariaHideApp={false}
            >
                <h3>{t('order.message.submittedSuccessfully')} </h3>
            <div className="confirm-order-btn btn-modal order" onClick={this.closeConfirmSuccess} >
            <div className="product-presentation-icon">
                <img className="close-icon confirm" src={closeIcon} onClick={this.closeConfirmSuccess} title="Zapri" alt="" />
            </div>
            <span className="modal-close-txt">
                { t('order.message.close')} </span>
            </div>
               {/*  <TranslationComponent/> */}
            </Modal>
        )
    }
}

//export default OrderSuccess

export default withTranslation()(OrderSuccess)


