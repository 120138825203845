import React, { Component } from "react"
import { withTranslation } from "react-i18next";
import {
    Slider,
    SliderMarker,
} from "@reach/slider";

import "@reach/slider/styles.css";

class SliderComponentRamLxcT extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...props,
            size: 1,
        }
    }

    componentWillReceiveProps(props) {
        this.setState({
            size: props.sliderValRam,
            newSliderValue: props.sliderValRam
        })
    }

    handleChange = (val) => {
        this.setState({ size: val });
        this.props.onChangeSliderValRam(this.state);
    };

    render() {
        const { t } = this.props;
        return (
            <div className="product-slider-div">

                <div className="product-description-text">

                    <div className="product-title-div vps" title="Minimalna količina RAM-a je 1GB na vsako vCPU enoto.">
                        {t('common.ram.title')}
                </div>
                </div>
                <div className="slider-div vps">
                    <Slider min={1} max={32} className="slider" defaultValue={1} step={1} value={Number(this.state.size) ?? 1} onChange={this.handleChange}>
                        {
                            new Array(257).fill("x").map((x, index) => (

                                <SliderMarker key={index} value={index}>
                                    {index === 1 && <span className="slider-number vps" key={index}> {"1 GB"}</span>}
                                    {index === 4 && <span className="slider-number vps" key={index}>{"4 GB"}</span>}
                                    {index === 8 && <span className="slider-number vps" key={index}>{"8 GB"}</span>}
                                    {index === 16 && <span className="slider-number vps" key={index}>{"16 GB"}</span>}
                                    {index === 24 && <span className="slider-number vps" key={index}>{"24 GB"}</span>}
                                    {index === 32 && <span className="slider-number vps" key={index}>{"32 GB"}</span>}
                                </SliderMarker>
                            ))
                        }
                    </Slider>
                </div>
                <br />
                <div className="price-div">
                    <div className="price">
                        {t('common.ram.size')}:   <span className="price-num">{this.state.size} GB</span>
                    </div>
                </div>
                <div><br />*{t('common.ram.note')}</div>
            </div >
        )
    }
}
const SliderComponentRamLxc = withTranslation()(SliderComponentRamLxcT);

export default SliderComponentRamLxc;