import React, { Component } from "react"
import SliderComponent from './SliderComponent'
import { withTranslation } from "react-i18next";

import "./../css/product.scss"
import "./../css/vps.scss"

class ProducDetailPanel2T extends Component {
    constructor(props) {
        super(props)
        let data = props.data
        this.state = ({
            calcType: data.calcType,
            keyItem: data.backgroundImage,
            backgroundImage: data.backgroundImage,
            logo: data.logo,
            title: data.title,
            description: data.description,
            productDescriptionBottom: data.productDescriptionBottom,
            tablePrice1: data.tablePrice1,
            tablePrice2: data.tablePrice2,
            tablePrice3: data.tablePrice3,
            tablePrice4: data.tablePrice4,
            tablePrice5: data.tablePrice5,
            keyText: data.keyText
        })
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <div className="panel-div product-detail-panel-1" ref="products" >
                    <div className="subpanel-div product-detail-subpanel-1" ref="products">
                        <hr />
                        <br />
                        <SliderComponent calcType={this.state.calcType} />
                        <br />
                        <hr />
                        <br />
                        <div className="restavriranje-data">

                            <ul className="product-ul">
                                <div className="product-title-div">{t('backupCommon.restoration.title')}</div>
                                <li>
                                    {t('backupCommon.restoration.line1')}
                                </li>
                                <li>
                                    {t('backupCommon.restoration.line2')}
                                </li>
                                <li>
                                    {t('backupCommon.restoration.line3')}
                                </li>
                            </ul>
                        </div>
                        <br />
                        <hr />
                        <br />
                        <div className="inf-calc-data">

                            <h4 className="product-title-div">{t('backupCommon.transferCalcTable.title')}</h4>
                            <table className="product-speed-table">
                                <thead>
                                    <tr>
                                        <th>{t('backupCommon.transferCalcTable.connectionSpeed.line1')}<br />{t('backupCommon.transferCalcTable.connectionSpeed.line2')}</th><th>{t('backupCommon.transferCalcTable.transferTime')}<br />100GB</th><th>{t('backupCommon.transferCalcTable.transferTime')}<br />1TB</th><th>{t('backupCommon.transferCalcTable.transferTime')}<br />10TB</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr><td>1 Mbps  </td><td>9 d 7 h      </td><td>92 d 14 h    </td><td>926 d        </td></tr>
                                    <tr><td>5 Mbps  </td><td>1 d 21 h     </td><td>18 d 13 h    </td><td>186 d        </td></tr>
                                    <tr><td>10 Mbps </td><td>22 h 14 min  </td><td>9 d 7 h      </td><td>93 d         </td></tr>
                                    <tr><td>20 Mbps </td><td>11 h 7 min   </td><td>4 d 16 h     </td><td>64 d 8 h     </td></tr>
                                    <tr><td>50 Mbps </td><td>4 h 27 min   </td><td>1 d 21 h     </td><td>18 d 13 h    </td></tr>
                                    <tr><td>100 Mbps</td><td>2 h 14 min   </td><td>22 h 14 min  </td><td>9 d 7 h      </td></tr>
                                    <tr><td>200 Mbps</td><td>1 h 7 min    </td><td>11 h 7 min   </td><td>4 d 16 h     </td></tr>
                                    <tr><td>500 Mbps</td><td>26 min 40 sec</td><td>4 h 27 min   </td><td>1 d 21 h     </td></tr>
                                    <tr><td>1 Gbps  </td><td>13 min 20 sec</td><td>2 h 14 min   </td><td>22 h 14 min  </td></tr>
                                    <tr><td>2 Gbps  </td><td>6 min 40 sec </td><td>1 h 7 min    </td><td>11 h 7 min   </td></tr>
                                    <tr><td>10 Gbps </td><td>1 min 20 sec </td><td>13 min 29 sec</td><td>2 h 14 min   </td></tr>
                                    <tr><td>20 Gbps </td><td>40 sec       </td><td>6 min 40 sec </td><td>1 h 7 min    </td></tr>
                                    <tr><td>40 Gbps </td><td>20 sec       </td><td>3 min 20 sec </td><td>33 min 20 sec</td></tr>
                                </tbody>
                            </table>
                            <br />
                            <br />
                        </div >
                    </div>
                </div >
            </>
        )
    }
}
const ProducDetailPanel2 = withTranslation()(ProducDetailPanel2T);

export default ProducDetailPanel2
