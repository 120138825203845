import React, { Component } from "react"
import { withTranslation } from "react-i18next";
import {
    Slider,
    SliderMarker,
} from "@reach/slider";
import "@reach/slider/styles.css";
import CalcUtil from './util/CalcUtil.js'


class SliderComponentVCPULxcT extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sliderValue: 0.1,
            newSliderValue: 0,
            size: 1,
        }
    }
    handleChange = (val) => {
        let newState = CalcUtil.calculateSizeVCPULxc(val);
        this.setState({
            newSliderValue: newState.val,
            size: newState.size
        });
        this.props.onChangeSliderValVCPULxc(this.state);
    };

    render() {
        const { t } = this.props;
        return (
            <div className="product-slider-div">

                <div className="product-description-text">
                    <div className="product-title-div vps">
                        {t('common.cpu.title')}
                </div>
                </div>
                <div className="slider-div vps">
                    <Slider min={1} max={8} className="slider" step={1} defaultValue={1} id={this.state.newSliderValue} value={this.state.newSliderValue} onChange={this.handleChange}>
                        {
                            new Array(19).fill("x").map((x, index) => (

                                <SliderMarker key={index} value={index}>
                                    {index === 1 && <span className="slider-number vps" key={index}> {"1 vCPU"}</span>}
                                    {index === 3 && <span className="slider-number vps" key={index}>{"3 vCPU"}</span>}
                                    {index === 5 && <span className="slider-number vps" key={index}>{"5 vCPU"}</span>}
                                    {index === 7 && <span className="slider-number vps last" key={index}>{"7 vCPU"}</span>}
                                </SliderMarker>
                            ))
                        }
                    </Slider>
                </div>
                <br />
                <div className="price-div">
                    <div className="price">
                        {t('common.cpu.size')}: <span className="price-num">{this.state.size} vCPU</span>
                    </div>
                </div>
            </div>
        )
    }
}
const SliderComponentVCPULxc = withTranslation()(SliderComponentVCPULxcT);

export default SliderComponentVCPULxc;