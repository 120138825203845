import React, { Component } from "react";
import Modal from "react-modal";
import OrderSuccess from "./OrderSuccess";
import SendOrderUserChoice from "./SendOrderUserChoice";
import Loader from "./../Loader";

import SendOrderUserData from "./SendOrderUserData";

import closeIcon from "./../assets/icons/close.png";

class SendOrderBackupProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      userChoice: props.userChoice,
      calcType: props.userChoice.calcType,
      modalIsOpen: false,
      showLoader: false,
      sendError: "",
    };
    this.child = React.createRef();
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    // this.showLoader = this.showLoader(this)
    this.childOrderSuccess = React.createRef();
    this.childSendUserData = React.createRef();
  }

  showLoader = (show) => {
    show && this.setState({ showLoader: true });
  };

  orderResult = (isSuccessful) => {
    if (isSuccessful) {
      this.openConfirmSuccess();
      this.setState({ sendError: "" });
    } else {
      //alert("NO")
      this.setState({ showLoader: false });
      this.setState({
        sendError:
          "Prišlo je do napake pri pošiljanju naročila. Prosimo, poskusite kasneje.",
      });
    }
  };

  openConfirmSuccess = () => {
    this.setState({ sendError: "" });
    this.child.current.openConfirmSuccess();
    //this.childOrderSuccess.current.openConfirmSuccess();
    this.setState({ showLoader: false });
  };

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  render() {
    let showLoader = this.state.showLoader
      ? "loader-parent loader-wait"
      : "loader-parent";

    return (
      <Modal
        className="modal-order"
        isOpen={this.state.modalIsOpen}
        onRequestClose={this.closeModal}
        contentLabel="Pošljite naročilo"
        ariaHideApp={false}
      >
        {this.state.showLoader && <Loader className={showLoader} />}

        <div className="modal-close-btn-panel">
          <div className="product-presentation-icon">
            <img
              className="close-icon"
              src={closeIcon}
              onClick={this.closeModal}
              title="Zapri"
              alt=""
            />
          </div>
        </div>
        <div className="order-form-data">
          <SendOrderUserChoice userChoice={this.props.userChoice} />
          <SendOrderUserData
            ref={this.childSendUserData}
            calcType={this.props.userChoice.calcType}
            userChoice={this.props.userChoice}
            orderSendResult={this.orderResult}
            showLoader={this.showLoader}
          />
        </div>
        <p className="send-error">
          {this.state.sendError ?? "Napaka pri pošiljanju naročila."}
        </p>
        <hr />
        <OrderSuccess
          ref={this.childOrderSuccess}
          innerRef={this.child}
          closeParent={this.closeModal}
        />
      </Modal>
    );
  }
}

export default SendOrderBackupProduct;
