import React, { Component } from "react"
import {
    Slider,
    SliderMarker,
} from "@reach/slider";

import "@reach/slider/styles.css";
import CalcUtil from './util/CalcUtil.js'
import SendOrderBackupProduct from "./SendOrderBackupProduct";
import { withTranslation } from "react-i18next";

import { PRODUCT_TYPES } from './../const/products'

class SliderComponentT extends Component {
    constructor(props) {
        super(props)
        //this.state.userChoice = { ...props }
        this.child = React.createRef();
        this.openModal = this.openModal.bind(this);
        switch (props.calcType) {
            case PRODUCT_TYPES.SYNOLOGY_BACKUP:
                this.state = {
                    sliderValue: 0.1,
                    /*backupPrice: "5.15 € mesečno oziroma 61.80 € letno",*/
                    backupSize: "1 TB",
                    setupPrice: 0.00,
                    newSliderValue: 1,
                    calcType: props.calcType,
                    obdobjeNajema: "common.finance.subscription.monthlyNoDiscount",
                    priceMonthly: "5.29",
                    priceYearly: "63.48",
                    priceFirstPayment: "5.29",
                }
                break
            case PRODUCT_TYPES.QNAP_BACKUP:
                this.state = {
                    sliderValue: 0.1,
                    /*backupPrice: "4.90 € mesečno oziroma 58.80 € letno",*/
                    backupSize: "1 TB",
                    setupPrice: 0.00,
                    newSliderValue: 1,
                    calcType: props.calcType,
                    obdobjeNajema: "common.finance.subscription.monthlyNoDiscount",
                    priceMonthly: "5.29",
                    priceYearly: "63.48",
                    priceFirstPayment: "5.29",
                }
                break
            case PRODUCT_TYPES.BACKUP_BOX:
                this.state = {
                    sliderValue: 0.1,
                    /*backupPrice: "4.64 € mesečno oziroma 55.68 € letno",*/
                    backupSize: "1 TB",
                    setupPrice: 0.00,
                    newSliderValue: 1,
                    calcType: props.calcType,
                    obdobjeNajema: "common.finance.subscription.monthlyNoDiscount",
                    priceMonthly: "2.96",
                    priceYearly: "35.52",
                    priceFirstPayment: "2.96",
                }
                break
            default:
                break
        }
        this.setObdobjeNajema = this.setObdobjeNajema.bind(this)
    }

    openModal() {
        this.child.current.openModal();
    }

    calcSliderVal(val, calcType) {
        let buttonVal = parseFloat(val)
        let paymentPeriod = this.state.obdobjeNajema
        let newState = CalcUtil.calculatePrice(buttonVal, paymentPeriod, calcType)
        this.setState({
            newSliderValue: buttonVal,
            sliderValue: this.state.sliderValue,
            backupSize: newState.backupSize,
            priceMonthly: newState.priceMonthly,
            priceYearly: newState.priceYearly,
            priceStartup: newState.priceStartup,
            priceFirstPayment: newState.priceFirstPayment
        });
    }

    setObdobjeNajema(e) {
        let val = e.currentTarget.value
        this.setState({ obdobjeNajema: val });

        let newState = CalcUtil.calculatePrice(this.state.newSliderValue, val, this.state.calcType)
        this.setState({
            priceMonthly: newState.priceMonthly,
            priceYearly: newState.priceYearly,
            priceFirstPayment: newState.priceFirstPayment
        });
    }

    handleChange = (val, calcType) => {
        this.setState({ newSliderValue: val });
        this.calcSliderVal(val, calcType)
    };

    updateSlider = (e, calcType) => {
        let val = parseFloat(e.currentTarget.value)
        this.calcSliderVal(val, calcType)
    };

    render() {
        const { t } = this.props;
        this.state.userChoice = { ...this.state }
        return (
            <div className="product-slider-div">

                <div className="product-description-text">
                    <div className="product-title-div">
                        {t('backupCalculation.title')}
                </div>
                </div>
                <div className="product-buttons-panel">
            
                    <button className="slider-predefined-btn" value={1} onClick={(e) => this.updateSlider(e, this.props.calcType)}>
                        <span className="slider-btn-text"> 1 TB </span>
                    </button>
                    <button className="slider-predefined-btn" value={5} onClick={(e) => this.updateSlider(e, this.props.calcType)}>
                        <span className="slider-btn-text"> 5 TB </span>
                    </button>
                    <button className="slider-predefined-btn" value={10} onClick={(e) => this.updateSlider(e, this.props.calcType)}>
                        <span className="slider-btn-text"> 10 TB </span>
                    </button>
                    <button className="slider-predefined-btn" value={50} onClick={(e) => this.updateSlider(e, this.props.calcType)}>
                        <span className="slider-btn-text"> 50 TB </span>
                    </button>
                    <button className="slider-predefined-btn" value={60} onClick={(e) => this.updateSlider(e, this.props.calcType)}>
                        <span className="slider-btn-text">100 TB</span>
                    </button>
                    <button className="slider-predefined-btn" value={80} onClick={(e) => this.updateSlider(e, this.props.calcType)}>
                        <span className="slider-btn-text">200 TB</span>
                    </button>

                </div>
                <div className="slider-div">
                    <Slider min={1} max={80} className="slider" defaultValue={1} id={this.state.newSliderValue} value={this.state.newSliderValue} onChange={(e) => this.handleChange(e, this.props.calcType)}>
                        {
                            new Array(81).fill("x").map((x, index) => (

                                <SliderMarker key={index} value={index}>
                                    {index === 1 && <span className="slider-number" key={index}> {"1 TB"}</span>}
                                    {index === 20 && <span className="slider-number" key={index}>{"20 TB"}</span>}
                                    {index === 50 && <span className="slider-number" key={index}>{"50 TB"}</span>}
                                    {index === 80 && <span className="slider-number last" key={index}>{"200 TB"}</span>}
                                </SliderMarker>
                            ))
                        }
                    </Slider>
                </div>
                <br />
                <div className="price-div">
                    <div className="price">
                        {t('backupCalculation.backupSize')}: <span className="price-num">{this.state.backupSize}</span>
                    </div>
                    <div className="vps-item">
                        <label>
                            <span className="price">{t('common.finance.subscription.period')}: </span>
                            <select
                                name="obdobjeNajema"
                                className="order-input obdobje-najema"
                                onChange={this.setObdobjeNajema}
                            >
                                <option value="common.finance.subscription.monthlyNoDiscount">{t('common.finance.subscription.monthlyNoDiscount')}</option>
                                <option value="common.finance.subscription.yearlyDiscount">{t('common.finance.subscription.yearlyDiscount')}</option>
                            </select>
                        </label>
                    </div>
                    <div className="price">
                        {t('common.finance.subscription.monthly')}: <span className="price-num">{this.state.priceMonthly} €</span>
                    </div>
                    <div className="price">
                        {t('common.finance.subscription.yearly')}: <span className="price-num">{this.state.priceYearly} €</span>
                    </div>
                    <div className="price">
                        {t('common.finance.subscription.setup')}: <span className="price-num">{this.state.setupPrice} €</span>
                    </div>

                    <div className="send-mail-div">
                        <a className="send-mail-vps" onClick={this.openModal} >
                            <button className="send-mail-btn">{t('common.finance.subscription.sendOrder')}</button>
                        </a>
                    </div>
                </div>
                <SendOrderBackupProduct ref={this.child} userChoice={this.state.userChoice} />
            </div>
        )
    }
}
const SliderComponent = withTranslation()(SliderComponentT);

export default SliderComponent;