import React, { Component } from "react";
import "./../../css/product.scss";
import ProductDetailPanel1 from "../ProductDetailPanel1";
import ProductDetailPanel2 from "../ProductDetailPanel2";
import { withTranslation } from "react-i18next";

import { PRODUCT_TYPES } from "./../../const/products";

let productDataBackupBox = {
  calcType: PRODUCT_TYPES.BACKUP_BOX,
  title: "backup.backupBox.title",
  description: "backup.backupBox.text1",
  backgroundImage: "../assets/img/g1.png",
  productDescriptionBottom:
    "V kolikor želite večjo količino, nam pišite na naslov ",
  tablePrice1: "0,00 €",
  tablePrice2: "0,98 € / ",
  tablePrice3: "1,98 € / ",
};

class ProductBackupBoxT extends Component {
  render() {
    return (
      <>
        <ProductDetailPanel1 data={productDataBackupBox} />
        <ProductDetailPanel2 data={productDataBackupBox} />
      </>
    );
  }
}
const ProductBackupBox = withTranslation()(ProductBackupBoxT);

export default ProductBackupBox;
