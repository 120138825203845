import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";

import NavigationBar from "./components/common/NavigationBar";
import MainMenu from "./components/common/MainMenu";
import LangMenu from "./components/common/LangMenu";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { faStroopwafel } from "@fortawesome/free-solid-svg-icons";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { faHome } from "@fortawesome/free-solid-svg-icons";

// import i18n (needs to be bundled ;))
import "./i18n";

library.add(faAngleDown);
library.add(faStroopwafel);
library.add(faCamera);
library.add(faHome);

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavigationBar />
        <LangMenu />
        <MainMenu />
      </BrowserRouter>
    </div>
  );
}

render(<App />, document.getElementById("root"));
