import React, { Component } from "react"
import SendOrderBackupProduct from './SendOrderBackupProduct'
import { withTranslation } from "react-i18next";

class LxcCalcT extends Component {


    constructor(props) {
        super(props)
        this.state = {}
        this.state.userChoice = { ...props }
        this.child = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        /*  if (prevState.someMirroredValue !== nextProps.someValue) {
             return {
                 derivedData: computeDerivedState(nextProps),
                 someMirroredValue: nextProps.someValue
             };
         } */
        // console.log("this.props: >> ", this.props)
        // alert(1, prevState, nextProps)
        //console.log("PREV STATE >> ", prevState)
        //console.log("NEXT PROPS >> ", nextProps)

        prevState.userChoice = nextProps
        return null;
    }

    openModal = () => {
        this.child.current.openModal();
    }
    
    render() {
        //  this.state.userChoice = { ...this.props }
        /*  console.log("props>> ", this.props)
         console.log("prop.userChoice>> (to je prazno, ker so objekti direktno pod this.props)", this.props.userChoice)
         console.log("state.userChoice>> ", this.state.userChoice)
 
         console.log("props.calcType>> ", this.props.calcType)
       */   //moramo poskrbeti, da bo v state-u v zadnjem stanju posodobljena verzija propsov pod userChoice
        const { t } = this.props;
        return (
            <div className="vps-calc">
                <br />
                <div className="vps-subtitle">
                    {t('order.currentCalculation.title')}
                </div>

                <div className="vps-recalc">
                    <hr />
                    <div>{t('order.currentCalculation.operationMode')}: <div className="send-order-choice"> {t(this.props.nacinDelovanja)}</div></div>
                    <div>{t('order.currentCalculation.resourceAllocation')}: <div className="send-order-choice"> {t(this.props.nacin)}</div></div>
                    <div>{t('order.currentCalculation.vCpuCount')}: <div className="send-order-choice"> {this.props.kolicinaCPU} vCPU</div></div>
                    <div>{t('order.currentCalculation.ramSize')}: <div className="send-order-choice"> {this.props.kolicinaRam} GB</div></div>
                    <div>{t('order.currentCalculation.primaryDiskType')}: <div className="send-order-choice"> {t(this.props.primarniTipDiska)}</div></div>
                    <div>{t('order.currentCalculation.primaryDiskSize')}: <div className="send-order-choice"> {this.props.kolicinaDisk} GB</div></div>
                    <div>{t('order.currentCalculation.netIPv4Count')}: <div className="send-order-choice"> {this.props.steviloJavnihIP}</div></div>
                    <div>{t('order.currentCalculation.netInterfaces')}: <div className="send-order-choice"> {this.props.steviloMreznihVmesnikov}</div></div>
                    <div>{t('order.currentCalculation.os')}: <div className="send-order-choice"> {t(this.props.os)}</div></div>
                    <div>{t('order.currentCalculation.subscriptionPeriod')}: <div className="send-order-choice"> {t(this.props.obdobjeNajema)}</div></div>

                    <hr />
                    <div>{t('order.currentCalculation.price')}: <div className="send-order-price"> {this.props.priceMonthly} € / {t('order.currentCalculation.monthly')}</div></div>
                    <div><div className="send-order-price"> {this.props.priceYearly} € / {t('order.currentCalculation.yearly')}</div></div>
                    <div>{t('order.currentCalculation.startupPrice')}:<div className="send-order-price"> {this.props.priceStartup} €</div></div>
                    <div className="send-mail-div">
                        <div className="send-mail-vps" onClick={this.openModal} >
                            <button className="send-mail-btn">{t('order.currentCalculation.sendOrder')}</button>
                        </div>
                    </div>
                </div>
                <SendOrderBackupProduct ref={this.child} userChoice={this.state.userChoice} />

            </div>
        )
    }



}
const LxcCalc = withTranslation()(LxcCalcT);

export default LxcCalc