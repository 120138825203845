import React from "react"

class AccordianContainer extends React.Component {
    render() {

        return (
            <div dangerouslySetInnerHTML={{ __html: this.props.item }} />
        );
    }
}

export default AccordianContainer