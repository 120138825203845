import React from "react";
import "./../../css/lang-menu.scss";
import i18n from "./../../i18n";
import { withTranslation } from "react-i18next";

//import sloZa from "./assets/icons/zastava-sl.png";
import sloZa from "./../../assets/icons/zastavaSlo.png";

import enZa from "./../../assets/icons/zastavaEng.png";

function LangMenu({ t }) {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <div className="lang-menu" key="lang-menu">
      <span className="lang-div"> Language: </span>
      {/*  <button onClick={() => changeLanguage("si")}>si</button>
       */}{" "}
      <img
        src={sloZa}
        alt=""
        onClick={() => changeLanguage("si")}
        className="lang-img"
      />
      {/*   <button onClick={() => changeLanguage("en")}>en</button>
       */}{" "}
      <img
        src={enZa}
        alt=""
        onClick={() => changeLanguage("en")}
        className="lang-img img-1"
      />
    </div>
  );
}

export default withTranslation()(LangMenu);
