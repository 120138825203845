import React, { Component } from "react";
import logo1 from "./../../assets/icons/Qnap_logo_black.svg";
import "./../../css/product.scss";
import ProductDetailPanel1 from "../ProductDetailPanel1";
import ProductDetailPanel2 from "../ProductDetailPanel2";
import { withTranslation } from "react-i18next";

import { PRODUCT_TYPES } from "./../../const/products";

let productDataQnap = {
  calcType: PRODUCT_TYPES.QNAP_BACKUP,
  title: "backup.qnap.title",
  description: "backup.qnap.text1",
  backgroundImage: "../assets/img/g1.png",
  logo: logo1,
  productDescriptionBottom:
    "V kolikor želite zakup količine nad 200 TB, nam pišite na naslov ",
  tablePrice1: "0,00 €",
  tablePrice2: "1,10 € / ",
  tablePrice3: "4,19 € / ",
};

class ProductQnapT extends Component {
  render() {
    return (
      <>
        <ProductDetailPanel1 data={productDataQnap} />
        <ProductDetailPanel2 data={productDataQnap} />
      </>
    );
  }
}
const ProductQnap = withTranslation()(ProductQnapT);

export default ProductQnap;
