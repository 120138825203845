
import React, { Suspense, lazy, Component } from "react"
import PublicRoute from '../../utils/PublicRoute';
import { Switch } from 'react-router-dom';

/* import Login from './auth/Login';
import Register from './auth/Register';
import Dashboard from './Dashboard'; */
/* import About from "./About";
 */

// must not be imported lazily
import ProductSynology from "../pages/ProductSynology";
import ProductQnap from "../pages/ProductQnap";

import ProductBackupBox from "../pages/ProductBackupBox";
import Lxc from "../pages/Lxc";
import Dedicated from "../pages/Dedicated";


const Colocation = lazy(() => import('../pages/Colocation'));
const PageFooter = lazy(() => import('./PageFooter'));
const ProductItems = lazy(() => import('../pages/ProductItems'));
const Terms = lazy(() => import('../pages/Terms'));
const Vps = lazy(() => import('../pages/Vps'));

/* import PrivateRoute from '../utils/PrivateRoute'; */



/* import Colocation from "./Colocation";
import PageFooter from "./PageFooter";
import ProductItems from "./ProductItems";
import Terms from './Terms';
import Vps from './Vps'; */


/* import About from "./About";
 */
const About = lazy(() => import('../pages/About'));

class NavigationBar extends Component {
    render() {
        return (
            <Suspense fallback={<div>Loading...</div>} key="suspense">
                <React.Fragment key="navbar">
                    <div className="content" key="content" id="content">
                        <Switch key="switch">
                            <PublicRoute exact path="/" key="root" component={About} />
                            <PublicRoute path="/synology-backup" key="syn" component={ProductSynology} />
                            <PublicRoute path="/qnap-backup" key="qnap" component={ProductQnap} />

                            <PublicRoute path="/backup-box" key="bb" component={ProductBackupBox} />
                            <PublicRoute path="/backup" key="backup" component={ProductItems} />
                            <PublicRoute path="/about" key="about" component={About} />
                            <PublicRoute path="/colocation" key="col" component={Colocation} />
                            <PublicRoute path="/vps" key="vps" component={Vps} />
                            <PublicRoute path="/lxc" key="vps" component={Lxc} />
                            <PublicRoute path="/terms" key="terms" component={Terms} />
                            <PublicRoute path="/dedicated" key="dedicated" component={Dedicated} />
                        </Switch>
                    </div>
                    <PageFooter />
                </React.Fragment>
            </Suspense>
        )
    }
}

export default NavigationBar;