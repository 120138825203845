import axios from "axios";

const instance = axios.create({
  baseURL: 'https://reavisys.si/api',
  //baseURL: "http://localhost:3001",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Content-Type",
    status: "ok",
  },
});
export default {
  sendOrder: (userChoice, userData, url) =>
    instance({
      method: "POST",
      url: url,
      data: {
        userChoice: userChoice,
        userData: userData,
      },
      headers: {
        "content-type": "application/json", // override instance defaults
      },
      transformResponse: [
        function (data) {
          console.log("Send order QNAP: Transforming data...");
          console.log("data::: ", data);
          //const json = JSON.parse(data)
          //console.log('json data::: ', json)
          return data;
        },
      ],
    }),
};
