import React, { Component } from "react";
//import LazyLoad from "react-lazy-load";
import { withTranslation } from "react-i18next";

import "./../css/product.scss";

class ProducDetailPanel1T extends Component {
  constructor(props) {
    super(props);
    let data = props.data;
    this.state = {
      calcType: data.calcType,
      keyItem: data.backgroundImage,
      backgroundImage: data.backgroundImage,
      logo: data.logo,
      title: data.title,
      description: data.description,
      productDescriptionBottom: data.productDescriptionBottom,
      tablePrice1: data.tablePrice1,
      tablePrice2: data.tablePrice2,
      tablePrice3: data.tablePrice3,
      keyText: data.keyText,
    };
    this.orderSendResult = this.orderSendResult.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  orderSendResult() {
    this.props.orderSendResult();
    this.setState({ modalIsOpen: false });
  }
  render() {
    const { t } = this.props;
    return (
      <>
        <div className="panel-div product-detail-panel" ref="products">
          <div
            className="subpanel-div product-detail-subpanel"
            style={{
              backgroundImage: this.state.backgroundImage,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="product-detail-inner-div">
              <div className="product-detail-box">
                {/*                 <LazyLoad once={true} forceVisible={true}>
                 */}{" "}
                <img
                  className="product-logo qnap"
                  alt=""
                  src={this.state.logo}
                />
                {/*                 </LazyLoad>
                 */}{" "}
                <hr className="product-hr" />
                <br />
                <br />
                <h1 className="product-detail-inner-text">
                  {t(this.state.title)}
                </h1>
                <br />
                <div className="product-detail-box-text">
                  <div className="product-description">
                    <div className="product-description-text">
                      {t(this.state.description)}
                    </div>
                  </div>
                  <div className="product-description">
                    <table className="product-table">
                      <thead>
                        <tr>
                          <th className="product">{t("backup.setup")}</th>
                          <th className="product">{t("backup.base")}</th>
                          <th className="product">1TB</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="product">{this.state.tablePrice1} </td>
                          <td className="product">
                            {this.state.tablePrice2} {t("backup.month")}
                          </td>
                          <td className="product">
                            {this.state.tablePrice3} {t("backup.month")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const ProducDetailPanel1 = withTranslation()(ProducDetailPanel1T);

export default ProducDetailPanel1;
