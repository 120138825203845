import React, { Component } from "react"
import SendOrderBackupProduct from './SendOrderBackupProduct'
import { withTranslation } from "react-i18next";

class DedicatedCalcT extends Component {


    constructor(props) {
        super(props)
        this.state = {}
        this.state.userChoice = { ...props }
        this.child = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        prevState.userChoice = nextProps
        return null;
    }

    openModal = () => {
        this.child.current.openModal();
    }

    render() {
        const { t } = this.props;
        return (
            <div className="vps-calc">
                <br />
                <div className="vps-subtitle">
                    {t('order.currentCalculation.title')}
            </div>

                <div className="vps-recalc">
                    <hr />
                    <div>{t('order.currentCalculation.selectedServer')}: <div className="send-order-choice"> {t(this.props.selectedServer)}</div></div>
                    <div>{t('order.currentCalculation.selectedStorage')}: <div className="send-order-choice"> {t(this.props.selectedStorage)}</div></div>
                   
                    {(this.props.selectedStorage ==  "dedicated.storage.nfs") &&
                        <div>
                            <div>{t('order.currentCalculation.primaryDiskSize')}: <div className="send-order-choice"> {this.props.diskSize} GB</div></div>
                        </div> 
                        }
                  {/*   <div>{t('order.currentCalculation.primaryDiskSize')}: <div className="send-order-choice"> {this.props.diskSize} GB</div></div>
                  */}   <div>{t('order.currentCalculation.netIPv4Count')}: <div className="send-order-choice"> {this.props.steviloJavnihIP}</div></div>
                    <div>{t('order.currentCalculation.os')}: <div className="send-order-choice"> {this.props.os}</div></div>
                    <div>{t('order.currentCalculation.subscriptionPeriod')}: <div className="send-order-choice"> {t(this.props.obdobjeNajema)}</div></div>

                    <hr />
                    <div>{t('order.currentCalculation.price')}: <div className="send-order-price"> {this.props.priceMonthly} € / {t('order.currentCalculation.monthly')}</div></div>
                    <div><div className="send-order-price"> {this.props.priceYearly} € / {t('order.currentCalculation.yearly')}</div></div>
                    <div>{t('order.currentCalculation.startupPrice')}:<div className="send-order-price"> {this.props.priceStartup} €</div></div>
                    <div className="send-mail-div">
                        <div className="send-mail-vps" onClick={this.openModal} >
                            <button className="send-mail-btn">{t('order.currentCalculation.sendOrder')}</button>
                        </div>
                    </div>
                </div>
                <SendOrderBackupProduct ref={this.child} userChoice={this.state.userChoice} />

            </div>
        )
    }



}
const DedicatedCalc = withTranslation()(DedicatedCalcT);

export default DedicatedCalc