import { PRODUCT_TYPES } from "./../../const/products"


const SLIDER_SYNOLOGY = {
    SLIDER_50TB: 50,
    BACKUP_PRICE_START: 1.10,
    BACKUP_PRICE_1TB: 4.19
}

const SLIDER_QNAP = {
    SLIDER_50TB: 50,
    BACKUP_PRICE_START: 1.10,
    BACKUP_PRICE_1TB: 4.19
}


const SLIDER_BACKUP_BOX = {
    SLIDER_50TB: 50,
    BACKUP_PRICE_START: 0.98,
    BACKUP_PRICE_1TB: 1.98
}

export default class CalcUtil {

    static calculatePrice(val, paymentPeriod, calcType) {

        let sliderChoice;
        switch (calcType) {
            case PRODUCT_TYPES.SYNOLOGY_BACKUP:
                sliderChoice = SLIDER_SYNOLOGY;
                break;
            case PRODUCT_TYPES.QNAP_BACKUP:
                sliderChoice = SLIDER_QNAP;
                break;
            case PRODUCT_TYPES.BACKUP_BOX:
                sliderChoice = SLIDER_BACKUP_BOX;
                break
            default:
                break
        }
        //alert("paymentPeriod:: ", paymentPeriod)

        // ToDo: calculate price
        let newState = {};
        let slider_position = val;
        let backup_size;
        let priceMonthly;
        let priceYearly;
        let priceFirstPayment;
        let priceStartup = 0;
        let priceYearDiscount = 0.06;


        if (slider_position <= sliderChoice.SLIDER_50TB) {
            backup_size = slider_position;
        }
        else {
            backup_size = (slider_position - 40) * 5;
        }

        priceMonthly = sliderChoice.BACKUP_PRICE_START + backup_size * sliderChoice.BACKUP_PRICE_1TB;

        priceYearly = 12 * priceMonthly;

        switch (paymentPeriod) {
            case "common.finance.subscription.monthlyNoDiscount":
                priceFirstPayment = priceStartup + priceMonthly;
                break;
            case "common.finance.subscription.yearlyDiscount":
                priceMonthly *= 1 - priceYearDiscount;
                priceYearly *= 1 - priceYearDiscount;
                priceFirstPayment = priceStartup + priceYearly;
                break;
            default:
                priceMonthly = 0;
                priceYearly = 0;
                priceFirstPayment = 0;
        }

        priceYearly = priceYearly.toFixed(2);
        priceMonthly = priceMonthly.toFixed(2);
        priceStartup = priceStartup.toFixed(2);
        priceFirstPayment = priceFirstPayment.toFixed(2);

        newState = {
            backupSize: backup_size + " TB",
            priceMonthly: priceMonthly,
            priceYearly: priceYearly,
            priceStartup: priceStartup,
            priceFirstPayment: priceFirstPayment,
        };
        return newState;

    }

    static calculateDiskSizeVps(val) {
        console.log("...calculateDiskSizeVps:: ", val)

        //val = val.size

        let size;
        if (val <= 57) {
            size = 14 + val * 2;
        }
        else if (val <= 90) {
            size = 128 + (val - 58) * 4;
        }
        else if (val <= 186) {
            size = 256 + (val - 90) * 8;
        }
        else {
            size = 1024 + (val - 186) * 32;
        }
        return size;
    }

    static calculateSizeVCPU(val) {

        let newState = {};
        let size;

        if (val <= 6) {
            size = val;
        }
        else {
            size = 6 + (val - 6) * 2;
        }
        newState = {
            val: val,
            size: size
        };
        return newState;
    }

    static calculateSizeVCPULxc(val) {

        let newState = {};
        let size;

        if (val <= 8) {
            size = val;
        }
        else {
            size = 8 + (val - 8) * 2;
        }
        newState = {
            val: val,
            size: size
        };
        return newState;
    }

    /* static calculateSizeDisk(val) {

        let newState = {};
        let slider_position = val;
        let backup_size;
        let backup_price;
        if (slider_position <= SLIDER_3TB) {
            backup_size = slider_position / 10;
            backup_price = BACKUP_PRICE_START + slider_position * BACKUP_PRICE_3TB_FOR_100GB;
        }
        else if (slider_position <= SLIDER_50TB) {
            backup_size = slider_position - 27;
            backup_price = BACKUP_PRICE_START + SLIDER_3TB * BACKUP_PRICE_3TB_FOR_100GB;
            backup_price += (slider_position - SLIDER_3TB) * BACKUP_PRICE_3TB_TO_50TB_FOR_TB;
        }
        else {
            backup_size = (slider_position - 72) * 10;
            backup_price = BACKUP_PRICE_START + SLIDER_3TB * BACKUP_PRICE_3TB_FOR_100GB;
            backup_price += (SLIDER_50TB - SLIDER_3TB) * BACKUP_PRICE_3TB_TO_50TB_FOR_TB;
            backup_price += (slider_position - SLIDER_50TB) * BACKUP_PRICE_50TB_TO_200TB_FOR_10TB;
        }
        newState = {
            backupSize: backup_size + " TB",
            backupPrice: Math.round((backup_price + Number.EPSILON) * 100) / 100 + " €"
        };
        return newState;
    } */
}