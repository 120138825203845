import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import {
  AccordionWithHeader,
  AccordionNode,
  AccordionHeader,
  AccordionPanel,
} from "react-accordion-with-header";
import AccordianContainer from "./AccordianContainer";
let tooltip1 =
  "asdf<strong style='color:lightblue;'>Samostojni način </strong> (privzeta vrednost)  <br/><br/> je način delovanja v katerem vaš virtualni strežnik teče samo na enem našem fizičnem strežniku<br/> in ne potrebuje nikakršne replikacije v primeru odpovedi strežnika<br/><br/><strong style='color:lightblue;'> Replikacija</strong><br/><br/>Ta način delovanja je nadgradnja Samostojnega načina delovanja<br/>.Pri replikaciji, kot že samo ime pove, se izvaja replikacija vašega virtualnega strežnika <br/> ki teče na enem izmed naših strežnikov na <br/>naš drug fizični strežnik in tako ste varno tudi ob izpadu strežnika,<br/> kjer je Vaš virtualni strežnik tekel prvotno.Po izpadu primarnega<br/> strežnika se po parih minutah vaš virtualni strežnik avtomatsko zažene<br/> na našem drugem strežniku.<br/>  !!!Na strežniku prižganem iz replikacije ni podatkov od zadnje <br/> replikacije do izpada.Možno je uredit dostop do primarnega strežnika za <br/> restavracijo.!!! <br/> !!!Primerno za okolja, kjer izguba parih minut podatkov ni problem. !!!<br/> !!!Ni primerno za transakcije!!! <br/><br/><strong style='color:lightblue;'>High avialibility</strong><br/><br/> Višja varnost podatkov od Replikacije.Podatki so<br/> shranjeni na skupni shrambi(Shared Storage) in ob izpadu primarnega<br/> strežnika, se Vaš virtualni strežnik na drugem fizičnem strežniku zažene<br/> z zadnjo možno verzijo podatkov. <br/>   !!!Primerno tudi za transakcije!!!";
let tooltip2 =
  "asdf Lokalni NVMe SSD<br/><br/>Disk lociran znoraj fizičnega strežnika na katerem teče Vaš virtualni strežnik<br/>+++ Višja hitrost prenosa podatkov<br/>+++ Hitrejša odzivnost diska<br/>--- Brez možnosti poganjanja virtualnega strežnika v High Availability načinu<br/>--- Podatki so dostopni šele, ko je fizičen strežnik v normalnem stanju.<br/><br/>Lokalni SATA/SASS SSD<br/>Disk lociran znotraj fizičnega strežnika na katerem teče Vaš virtualni strežnik<br/>+++ Višja hitrost prenosa podatkov<br/>+++ Hitrejša odzivnost diska<br/>--- Brez možnosti poganjanja virtualnega strežnika v High Availability<br/>načinu<br/>--- Podatki so dostopni šele, ko je fizičen strežnik v normalnem stanju.<br/><br/>Lokalni NVMe SSD<br/><br/>Disk lociran znotraj fizičnega strežnika na katerem teče<br/>Vaš virtualni strežnik<br/>+++ Višja hitrost prenosa podatkov<br/>+++ Hitrejša odzivnost diska<br/>--- Brez možnosti poganjanja virtualnega strežnika v High Availability načinu <br/>--- Podatki so dostopni šele, ko je fizičen strežnik v normalnem stanju.<br/>Deljeni SSD<br/><br/> <br/>Diskovni prostor je deljen med večimi fizičnimi strežniki. <br/>+++ Podatki so vedno dostopni, tudi če primarni strežnik odpove. <br/>+++ Možnost poganjanja strežnika v High Availability načinu. <br/>--- Nižja hitrost prenosa podatkov <br/>--- Višji odzivni časi. <br/><br/> Deljeni SSD Cached <br/>Diskovno polje sestavljajo HDD diski, za hitrejši <br/>predpomnenje pa se uporabljajo NVMe SSD diski.";
let tooltip3 =
  "asdfDodelitev sredstev<br/>Zasedenost procesorja je zgolj začasne narave in v 15 minutnem intervalu ne preseže 50% zasedenosti.<br/>Zasedenost procesorja je deljena med več uporabniki, število vCPU enot je višje od celotnega števila CPU jeder fizičnega strežnika.<br/><br/>Shared vCPU<br/>Zasedenost procesorja je zgolj začasne narave in v 15 minutnem intervalu ne preseže 50% zasedenosti.<br/>Zasedenost procesorja je deljena med več uporabniki, število vCPU enot je višje od celotnega števila CPU jeder fizičnega strežnika<br/><br/>Dedicated vCPU<br/><br/>Ni omejitve zasedenosti CPUja. Število vCPU enot je manjše ali enako števiulu CPU jeder fizičnega strežnika.";
let tooltip4 =
  "asdfProcesor z visoko frekvenco CPU jedra. !!! Primerno za programsko opremo z visoko licenčnino !!!";

class AccordionT extends Component {
  constructor(props) {
    super(props);

    this.state = {
      keyItem: props.keyItem,
      keyText: props.keyText,
    };
    //console.log('\n\n..this.state.keyText.. ', this.state.keyText)
  }

  actionCallback = (panels, state) => {
    let text;
    switch (this.state.keyItem) {
      case 1:
        text = tooltip1;
        break;
      case 2:
        text = tooltip2;
        break;
      case 3:
        text = tooltip3;
        break;
      case 4:
        text = tooltip4;
        break;
      default:
        text = tooltip1;
        break;
    }

    this.setState({
      ...this.state,
      inputText: text,
    });
  };

  render() {
    const { t } = this.props;

    let itemText1 = t(this.state.keyText);

    return (
      <AccordionWithHeader
        firstOpen={false}
        actionCallback={this.actionCallback}
        keyItem={this.state.keyItem}
      >
        <AccordionNode key={this.state.key}>
          <AccordionHeader
            horizontalAlignment="centerSpaceAround"
            verticalAlignment="center"
          >
            <div>{t("common.details")}</div>
            <div></div>
          </AccordionHeader>
          <AccordionPanel>
            <AccordianContainer item={itemText1} />
          </AccordionPanel>
        </AccordionNode>
      </AccordionWithHeader>
    );
  }
}
const Accordion = withTranslation()(AccordionT);

export default Accordion;
