import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { withTranslation } from "react-i18next";

import apiCalls from "./../api/apiCalls";

import { PRODUCT_TYPES } from "../const/products";
import { API_URL } from "../api/apiUrl";
import { ERRORS } from "../const/errors";

/*** class for handling user form data */
class SendOrderUserDataT extends Component {
  constructor(props) {
    super(props);
    //console.log('------ SEND ORDER PRODUCT ', props)
    this.state = {
      ...props,
      calcType: props.calcType,
      showCompanyData: false,
      showLoader: false,
      fields: {
        firstname: "",
        lastname: "",
        email: "",
        address: "",
        post: "",
        phone: "",
        postNum: "",
        country: "",
        isCompany: false,
        companyName: "",
        idDDV: "",
        companyPost: "",
        companyPostNum: "",
        companyCountry: "",
        comment: "",
        obdobjeNajema: "12 mesecev",
        terms: false,
      },
      errors: {},
    };
    this.orderSendResult = this.orderSendResult.bind(this);
    this.showLoader = this.showLoader.bind(this);
    this.handleCaptchaResponseChange = this.handleCaptchaResponseChange.bind(
      this
    );
  }

  showLoader(isWaiting) {
    this.props.showLoader(isWaiting);
  }

  orderSendResult(isSuccessful) {
    this.props.orderSendResult(isSuccessful);
    this.setState({ modalIsOpen: false });
  }

  isWaitingForOrder() {
    this.props.isWaitingForOrder();
    this.setState({ showLoader: false });
  }

  handleValidation() {
    const { t } = this.props;
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["firstname"]) {
      formIsValid = false;
      errors["firstname"] = t("ERRORS.NOT_EMPTY");
    }

    /* if (typeof fields["fistname"] !== "undefined") {
            if (!fields["firstname"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["firstname"] = ONLY_LETTERS;
            }
        } */
    if (!fields["lastname"]) {
      formIsValid = false;
      errors["lastname"] = t("ERRORS.NOT_EMPTY");
    }

    /*  if (typeof fields["lastname"] !== "undefined") {
             if (!fields["lastname"].match(/^[a-zA-Z]+$/)) {
                 formIsValid = false;
                 errors["lastname"] = ONLY_LETTERS;
             }
         } */
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = ERRORS.NOT_EMPTY;
    }

    if (typeof fields["email"] !== "undefined") {
      let lastAtPos = fields["email"].lastIndexOf("@");
      let lastDotPos = fields["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          fields["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          fields["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = t("ERRORS.EMAIL_NOT_VALID");
      }
    }

    if (!fields["address"]) {
      formIsValid = false;
      //errors["address"] = ERRORS.NOT_EMPTY;
      errors["address"] = t("ERRORS.NOT_EMPTY");
    }

    if (!fields["phone"]) {
      formIsValid = false;
      errors["phone"] = t("ERRORS.NOT_EMPTY");
    }
    if (!fields["postNum"]) {
      formIsValid = false;
      errors["postNum"] = t("ERRORS.NOT_EMPTY");
    }
    if (!fields["post"]) {
      formIsValid = false;
      errors["post"] = t("ERRORS.NOT_EMPTY");
    }
    if (!fields["country"]) {
      formIsValid = false;
      errors["country"] = t("ERRORS.NOT_EMPTY");
    }

    /* if (typeof fields["country"] !== "undefined") {
            if (!fields["country"].match(/^[a-zA-Z]+$/)) {
                formIsValid = false;
                errors["country"] = ONLY_LETTERS;
            }
        } */
    if (!fields["terms"]) {
      formIsValid = false;
      errors["terms"] = t("ERRORS.TERMS");
    }

    if (this.state.showCompanyData) {
      if (!fields["companyName"]) {
        formIsValid = false;
        errors["companyName"] = t("ERRORS.NOT_EMPTY");
      }

      if (!fields["idDDV"]) {
        formIsValid = false;
        errors["idDDV"] = t("ERRORS.NOT_EMPTY");
      }
      if (!fields["companyAddress"]) {
        formIsValid = false;
        errors["companyAddress"] = t("ERRORS.NOT_EMPTY");
      }
      if (!fields["companyPostNum"]) {
        formIsValid = false;
        errors["companyPostNum"] = t("ERRORS.NOT_EMPTY");
      }
      if (!fields["companyPost"]) {
        formIsValid = false;
        errors["companyPost"] = t("ERRORS.NOT_EMPTY");
      }
      if (!fields["companyCountry"]) {
        formIsValid = false;
        errors["companyCountry"] = t("ERRORS.NOT_EMPTY");
      }
    } else {
      fields["isCompany"] = false;
    }

    //console.log("this.state.recaptchaResponse:: ", this.state.recaptchaResponse)
    if (!this.state.recaptchaResponse) {
      return;
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange(field, e) {
    let fields = this.state.fields;

    if (e.target.type === "checkbox" && e.target.name === "terms") {
      if (e.target.checked) {
        fields["terms"] = true;
      } else fields["terms"] = false;
      this.setState({ fields });
    } else {
      fields[field] = e.target.value;
      this.setState({ fields });
    }
  }

  handleCaptchaResponseChange(response) {
    //        console.log("CAPTCHA: response", response)
    this.setState({
      recaptchaResponse: response,
    });
  }

  order = (e) => {
    //  this.refs.parent.style.backgroundColor = "purple"
    //this.refs.products.style.background = "linear-gradient(to bottom, #8aa9ff 1px, #fffefe 3px)";

    e.preventDefault();
    // delete next line... for test only
    //  this.orderSendResult(true)
    // end delete next line
    if (this.handleValidation()) {
      let userChoice = this.state.userChoice;
      let userData = this.state.fields;
      let url;
      switch (this.state.calcType) {
        case PRODUCT_TYPES.QNAP_BACKUP:
          url = API_URL.QNAP_BACKUP;
          break;
        case PRODUCT_TYPES.SYNOLOGY_BACKUP:
          url = API_URL.SYNOLOGY_BACKUP;
          break;
        case PRODUCT_TYPES.BACKUP_BOX:
          url = API_URL.BACKUP_BOX;
          break;
        case PRODUCT_TYPES.VPS:
          url = API_URL.VPS;
          break;
        case PRODUCT_TYPES.LXC:
          url = API_URL.LXC;
          break;
        case PRODUCT_TYPES.COLOCATION:
          url = API_URL.COLOCATION;
          break;
        case PRODUCT_TYPES.DEDICATED:
          url = API_URL.DEDICATED;
          break;
        default:
          url = null;
          break;
      }
      if (url) {
        this.showLoader(true);

        let userChoice1 = { ...userChoice };

        userChoice1.t = null;
        userChoice1.i18n = null;
        console.log("PRED   apiCalls.sendOrder:: ", userChoice1, userData, url);

        apiCalls
          .sendOrder(userChoice1, userData, url)
          .then((res) => {
            console.log("RESULT SendOrderUserData:: ", res);

            this.orderSendResult(true);
          })
          .catch((err) => {
            console.log("ERROR SendOrderUserData:: ", err);
            this.orderSendResult(false);
            this.showLoader(false);
          });
      } else {
        this.orderSendResult(false);
      }
      return;
    }
  };

  componentDidMount() {
    if (this.recaptcha) {
      this.recaptcha.reset();
    }
  }

  render() {
    const { showCompanyData } = this.state;

    const { t } = this.props;
    return (
      <div className="order-form-data">
        <hr />
        <h3>{t("order.yourData")}</h3>
        <label className="modal-label">
          <span className="modal-text">{t("order.user.name")}: </span>
          <input
            type="text"
            id="firstname"
            maxLength="30"
            name="firstname"
            className="order-input"
            onChange={this.handleChange.bind(this, "firstname")}
            value={this.state.fields["firstname"]}
          />
          <span className="error">{this.state.errors["firstname"]}</span>
        </label>
        <label className="modal-label">
          <span className="modal-text">{t("order.user.surname")}:</span>
          <input
            type="text"
            id="lastname"
            maxLength="30"
            name="lastname"
            className="order-input"
            onChange={this.handleChange.bind(this, "lastname")}
            value={this.state.fields["lastname"]}
          />
          <span className="error">{this.state.errors["lastname"]}</span>
        </label>
        <label className="modal-label">
          <span className="modal-text">{t("order.user.phoneNumber")}: </span>
          <input
            type="text"
            id="phone"
            maxLength="30"
            name="phone"
            className="order-input"
            onChange={this.handleChange.bind(this, "phone")}
            value={this.state.fields["phone"]}
          />
          <span className="error">{this.state.errors["phone"]}</span>
        </label>

        <label className="modal-label">
          <span className="modal-text">{t("order.user.email")}: </span>
          <input
            type="text"
            id="email"
            maxLength="30"
            name="email"
            className="order-input"
            onChange={this.handleChange.bind(this, "email")}
            value={this.state.fields["email"]}
          />
          <span className="error">{this.state.errors["email"]}</span>
        </label>

        <label className="modal-label">
          <span className="modal-text">{t("order.user.address")}:</span>
          <input
            type="text"
            id="address"
            maxLength="50"
            name="address"
            className="order-input"
            onChange={this.handleChange.bind(this, "address")}
            value={this.state.fields["address"]}
          />
          <span className="error">{this.state.errors["address"]}</span>
        </label>
        <label className="modal-label">
          <span className="modal-text">
            {t("order.user.postOfficeNumber")}:
          </span>
          <input
            type="text"
            id="postNum"
            maxLength="10"
            name="postNum"
            className="order-input"
            onChange={this.handleChange.bind(this, "postNum")}
            value={this.state.fields["postNum"]}
          />
          <span className="error">{this.state.errors["postNum"]}</span>
        </label>

        <label className="modal-label">
          <span className="modal-text">{t("order.user.postOffice")}:</span>
          <input
            type="text"
            id="post"
            maxLength="50"
            name="post"
            className="order-input"
            onChange={this.handleChange.bind(this, "post")}
            value={this.state.fields["post"]}
          />
          <span className="error">{this.state.errors["post"]}</span>
        </label>
        <label className="modal-label">
          <span className="modal-text">{t("order.user.country")}:</span>
          <input
            type="text"
            id="country"
            maxLength="30"
            name="country"
            className="order-input"
            onChange={this.handleChange.bind(this, "country")}
            value={this.state.fields["country"]}
          />
          <span className="error">{this.state.errors["country"]}</span>
        </label>
        <label className="modal-label">
          <span className="modal-text">{t("order.company.title")}</span>
          <input
            id="company"
            name="company"
            type="checkbox"
            checked={this.state.isCompany}
            onChange={() =>
              this.setState({ showCompanyData: !showCompanyData })
            }
          />
        </label>

        <div
          className="company-fieldset"
          style={{ display: this.state.showCompanyData ? "block" : "none" }}
        >
          <label className="modal-label">
            <span className="modal-text">{t("order.company.name")}:</span>
            <input
              id="companyName"
              maxLength="30"
              name="companyName"
              type="text"
              className="order-input"
              onChange={this.handleChange.bind(this, "companyName")}
              value={this.state.fields["companyName"]}
            />
            <span className="error">{this.state.errors["companyName"]}</span>
          </label>
          <label className="modal-label">
            <span className="modal-text">{t("order.company.vatID")}: </span>
            <input
              type="text"
              id="idDDv"
              maxLength="30"
              name="idDDV"
              className="order-input"
              onChange={this.handleChange.bind(this, "idDDV")}
              value={this.state.fields["idDDV"]}
            />
            <span className="error">{this.state.errors["idDDV"]}</span>
          </label>
          <label className="modal-label">
            <span className="modal-text">{t("order.company.address")}: </span>
            <input
              type="text"
              id="companyAddress"
              maxLength="50"
              name="companyAddress"
              className="order-input"
              onChange={this.handleChange.bind(this, "companyAddress")}
              value={this.state.fields["companyAddress"]}
            />
            <span className="error">{this.state.errors["companyAddress"]}</span>
          </label>
          <label className="modal-label">
            <span className="modal-text">
              {t("order.company.postOfficeNumber")}:{" "}
            </span>
            <input
              type="text"
              id="companyPostNum"
              maxLength="10"
              name="companyPostNum"
              className="order-input"
              onChange={this.handleChange.bind(this, "companyPostNum")}
              value={this.state.fields["companyPostNum"]}
            />
            <span className="error">{this.state.errors["companyPostNum"]}</span>
          </label>
          <label className="modal-label">
            <span className="modal-text">
              {t("order.company.postOffice")}:{" "}
            </span>
            <input
              type="text"
              id="companyPost"
              maxLength="50"
              name="companyPost"
              className="order-input"
              onChange={this.handleChange.bind(this, "companyPost")}
              value={this.state.fields["companyPost"]}
            />
            <span className="error">{this.state.errors["companyPost"]}</span>
          </label>
          <label className="modal-label">
            <span className="modal-text">{t("order.company.country")}:</span>
            <input
              type="text"
              id="companyCountry"
              maxLength="50"
              name="companyCountry"
              className="order-input"
              onChange={this.handleChange.bind(this, "companyCountry")}
              value={this.state.fields["companyCountry"]}
            />
            <span className="error">{this.state.errors["companyCountry"]}</span>
          </label>
          <br />
        </div>
        <label className="modal-label">
          <span className="modal-text">{t("order.comment")}</span>
          <input
            type="text"
            id="comment"
            maxLength="50"
            name="companyCountry"
            className="order-input"
            onChange={this.handleChange.bind(this, "comment")}
            value={this.state.fields["comment"]}
          />
          <span className="error">{this.state.errors["comment"]}</span>
        </label>
        <br />
        <label className="modal-label">
          <input
            id="terms"
            name="terms"
            onChange={this.handleChange.bind(this, "terms")}
            checked={this.state.fields["terms"]}
            type="checkbox"
          />
          <span className="terms">
            {t("order.agree1")}{" "}
            <a target="_blank" href="/terms">
              {t("order.agree2")}
            </a>
          </span>
          <span className="error">{this.state.errors["terms"]}</span>
          <br />
        </label>
        <br />
        <div className="recaptcha">
          <ReCAPTCHA
            ref={(el) => {
              this.recaptcha = el;
            }}
            sitekey="6LcLxssZAAAAADaG4O-rdUq2FyvDQmDCTtEHoUZ-"
            onChange={this.handleCaptchaResponseChange}
          />
          <br />
          <hr />
        </div>
        <div className="btn-modal order" onClick={this.order}>
          {t("order.currentCalculation.sendOrder")}
        </div>
      </div>
    );
  }
}
const SendOrderUserData = withTranslation()(SendOrderUserDataT);

export default SendOrderUserData;
