import React, { Component } from "react"
import { withTranslation } from "react-i18next";
import {
    Slider,
    SliderMarker,
} from "@reach/slider";

import CalcUtil from './util/CalcUtil'


import "@reach/slider/styles.css";

class SliderComponentDiskT extends Component {
    constructor(props) {
        super(props)
        this.state = {
            size: 16
        }
    }
    handleChange = (val) => {
        let val1 = CalcUtil.calculateDiskSizeVps(val)
        console.log("val1>> ", val1)
        this.setState({ size: val1 });
        this.props.onChangeSliderValDisk(this.state);
    };
    render() {
        const { t } = this.props;
        return (
            <div className="product-slider-div">

                <div className="product-description-text">
                    <div className="product-title-div vps">
                        {t('common.diskSize.title')}
                    </div>
                </div>
                <div className="slider-div vps">
                    <Slider min={1} max={250} className="slider" defaultValue={1} step={1} id={this.state.newSliderValue} value={this.state.newSliderValue} onChange={this.handleChange}>
                        {
                            new Array(250).fill("x").map((x, index) => (

                                <SliderMarker key={index} value={index}>
                                    {index === 1 && <span className="slider-number vps" key={index}> {"16 GB"}</span>}
                                    {index === 57 && <span className="slider-number vps" key={index}>{"128 GB"}</span>}
                                    {index === 90 && <span className="slider-number vps last" key={index}>{"256 GB"}</span>}
                                    {index === 120 && <span className="slider-number vps last" key={index}>{"512 GB"}</span>}
                                    {index === 186 && <span className="slider-number vps last" key={index}>{"1 TB"}</span>}
                                    {index === 248 && <span className="slider-number vps last" key={index}>{"3 TB"}</span>}

                                </SliderMarker>
                            ))
                        }
                    </Slider>
                </div>
                <br />
                <div className="price-div">
                    <div className="price">
                        {t('common.diskSize.size')}: <span className="price-num">{this.state.size} GB</span>
                    </div>
                </div>
            </div>
        )
    }
}
const SliderComponentDisk = withTranslation()(SliderComponentDiskT);

export default SliderComponentDisk;