import React, { Component } from 'react'
import SliderComponentDisk from '../SliderComponentDisk'
import { withTranslation } from "react-i18next";

import DedicatedCalc from '../DedicatedCalc'

import { PRODUCT_TYPES } from './../../const/products'

import "./../../css/vps.scss"

import bck1_xxl from "../../assets/img/ee.png"
import bck1_xl from "../../assets/img/ee.png"
import bck1_l from "../../assets/img/ee.png"
import bck1_md from "../../assets/img/ee.png"
import bck1_sm from "../../assets/img/ee.png"

let isSmall, isMedium, isLarge, isXLarge, isXXLarge

class DedicatedT extends Component {

    constructor(props) {
        super(props)
        
        this.state = ({
            selectedServer: "dedicated.server.rPi4-2",
            selectedStorage: "dedicated.storage.microSD32GB",
            diskSize: "16",
            steviloJavnihIP: "0",
            os: "Ubuntu Server 20.04 LTS",
            activeKey: ['1'],
            obdobjeNajema: "common.finance.subscription.monthlyNoDiscount",
            priceMonthly: "5.90",
            priceYearly: "70.80",
            priceStartup: "5.90",
            priceFirstPayment: "5.90",
            showNFS: true,
            isSmall: window.innerWidth < 480,
            isMedium: window.innerWidth > 481 && window.innerWidth <= 768,
            isLarge: window.innerWidth > 769 && window.innerWidth <= 1499,
            isXLarge: window.innerWidth > 1500 && window.innerWidth <= 2800,
            isXXLarge: window.innerWidth > 2801 && window.innerWidth < 3500,
          
            calcType: PRODUCT_TYPES.DEDICATED
        });

     
        isSmall = window.innerWidth < 480
        isMedium = window.innerWidth > 481 && window.innerWidth <= 768
        isLarge = window.innerWidth > 769 && window.innerWidth <= 1499
        isXLarge = window.innerWidth > 1500 && window.innerWidth <= 2800
        isXXLarge = window.innerWidth > 2801 && window.innerWidth < 3500

        this.setSliderValDisk = this.setSliderValDisk.bind(this)
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        if (isXXLarge) {
            this.setState({ backgroundImage1: bck1_xxl })
        }
        if (isXLarge) {
            this.setState({ backgroundImage1: bck1_xl })
        }
        if (isLarge) {
            this.setState({ backgroundImage1: bck1_l })
        }
        if (isMedium) {
            this.setState({ backgroundImage1: bck1_md })
        }
        if (isSmall) {
            this.setState({ backgroundImage1: bck1_sm })
        }
        window.scrollTo(0, 0);
    }

    onChangeCollapse = (activeKey) => {
        this.setState({
            activeKey,
        });
    }

    handleChange = (evt) => {

        const value1 =
            evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
        
        this.setState({
            ...this.state,
            //showNFS: isNFS,
            [evt.target.name]: value1,
        }, function () { 
            this.calcuatePrice() 
           
            if(this.state.selectedStorage === "dedicated.storage.nfs"){
                this.setState({showNFS: false})
            }
            else {
                this.setState({showNFS: true})
            } 
        });
    }
    calcuatePrice() {

        let steviloJavnihIP = this.state.steviloJavnihIP;
        let obdobjeNajema = this.state.obdobjeNajema;
        let selectedServer = this.state.selectedServer;
        let selectedStorage = this.state.selectedStorage;
        let diskSize = this.state.diskSize;

        let priceMonthly = 0;
        let priceYearDiscount = 0.06;
        let priceYearly = 0;
        let priceStartup = 0;
        let priceFirstPayment = 0;

        let pricePublicIPv4 = 0.98;
        // let pricePublicIPv6 = 0.32;

        // let priceTraffic = 1.25;


        let pricePi2 = 5.18;
        let pricePi4 = 5.64;
        let pricePi8 = 6.91;

        let priceMicrosd32 = 0.72;
        let priceMicrosd128 = 1.33;
        let priceMicrosd256 = 2.16;
        let priceNfs = 0.02;

        priceStartup = 0;

        

        switch (selectedServer) {
            case "dedicated.server.rPi4-2":
                priceStartup = pricePi2;
                priceMonthly = pricePi2;
                break;
            case "dedicated.server.rPi4-4":
                priceStartup = pricePi4;
                priceMonthly = pricePi4;
                break;
            case "dedicated.server.rPi4-8":
                priceStartup = pricePi8;
                priceMonthly = pricePi8;
                break;
            default:
                priceStartup = 0;
                priceMonthly = 0;
        }

        switch (selectedStorage) {
            case "dedicated.storage.microSD32GB":
                priceStartup += priceMicrosd32;
                priceMonthly += priceMicrosd32;
                break;
            case "dedicated.storage.microSD128GB":
                priceStartup += priceMicrosd128;
                priceMonthly += priceMicrosd128;
                break;
            case "dedicated.storage.microSD256GB":
                priceStartup += priceMicrosd256;
                priceMonthly += priceMicrosd256;
                break;
            case "dedicated.storage.nfs":
                priceMonthly += priceNfs * diskSize;
                break;
            default:
                priceStartup = 0;
                priceMonthly = 0;
        }

        priceMonthly += steviloJavnihIP * pricePublicIPv4;

        priceYearly = priceMonthly * 12;

        switch (obdobjeNajema) {
            case "common.finance.subscription.monthlyNoDiscount":
                priceFirstPayment = priceStartup + priceMonthly;
                break;
            case "common.finance.subscription.yearlyDiscount":
                priceMonthly *= 1 - priceYearDiscount;
                priceYearly *= 1 - priceYearDiscount;
                priceFirstPayment = priceStartup + priceYearly;
                break;
            default:
                priceMonthly = 0;
                priceYearly = 0;
        }

        priceYearly = priceYearly.toFixed(2);
        priceMonthly = priceMonthly.toFixed(2);
        priceStartup = priceStartup.toFixed(2);
        priceFirstPayment = priceFirstPayment.toFixed(2);

        this.setState({
            ...this.state,
            priceMonthly: priceMonthly,
            priceYearly: priceYearly,
            priceStartup: priceStartup,
            priceFirstPayment: priceFirstPayment
        });
    }

    setSliderValDisk(val) {
        this.setState({
            ...this.state,
            diskSize: val.size
        });
        this.calcuatePrice()
    }

    render() {
        const { t } = this.props;
        
        return (
            <>
                <div className="panel-div vps-panel" >
                    <div className="subpanel-div vps-subpanel" style={{
                        backgroundImage: `url(${this.state.backgroundImage1})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    }}>
                        <div className="vps-inner-div">
                            <div className="vps-box">

                                <div className="vps-box-text">
                                    <div className="vps-box-text-1">
                                        <h1 className="vps-inner-title">{t('dedicated.title')}</h1>
                                        <div className="vps-li first">{t('dedicated.text1')}
                                        <br />{t('dedicated.text2')}</div>
                                        <hr />
                                        <div className="vps-mail">{t('dedicated.question1')}<br />{t('dedicated.question2')}<a href="mailto:sales@reavisys.si"> sales@reavisys.si</a>.</div>
                                    </div>
                                    <div className="vps-box-text-2">
                                        <div className="vps-li">{t('dedicated.included.line1')}</div>
                                        <div className="vps-li">{t('dedicated.included.line2')}</div>
                                        <div className="vps-li">{t('dedicated.included.line3')}</div>
                                        <div className="vps-li">{t('dedicated.included.line4')}</div>
                                        <div className="vps-li"><b>{t('dedicated.included.line5')}</b></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="panel-div vps-panel-1" >
                    <div className="subpanel-div vps-subpanel-1">

                        <div className="vps-description">
                            <p>{t('dedicated.title2')}</p>
                        </div>
                        <div className="vps-div">
                            <div className="vps-div-calc">
                                <div className="vps-item-title">{t('dedicated.server.select')}</div>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            name="selectedServer"
                                            value="dedicated.server.rPi4-2"
                                            checked={this.state.selectedServer === "dedicated.server.rPi4-2"}
                                            onChange={this.handleChange}
                                        />
                                        {t('dedicated.server.rPi4-2')}
                                        </label>
                                </div>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            name="selectedServer"
                                            value="dedicated.server.rPi4-4"
                                            checked={this.state.selectedServer === "dedicated.server.rPi4-4"}
                                            onChange={this.handleChange}
                                        />
                                        {t('dedicated.server.rPi4-4')}
                                        </label>
                                </div>
                                <div>
                                    <label>
                                        <input type="radio"
                                            name="selectedServer"
                                            value="dedicated.server.rPi4-8"
                                            checked={this.state.selectedServer === "dedicated.server.rPi4-8"}
                                            onChange={this.handleChange}
                                        />
                                        {t('dedicated.server.rPi4-8')}
                                        </label>
                                </div>
                                <br />
                                <div className="vps-item-title">{t('dedicated.storage.select')}</div>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            name="selectedStorage"
                                            value="dedicated.storage.microSD32GB"
                                            checked={this.state.selectedStorage === "dedicated.storage.microSD32GB"}
                                            onChange={this.handleChange}
                                        />
                                        {t('dedicated.storage.microSD32GB')}
                                        </label>
                                </div>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            name="selectedStorage"
                                            value="dedicated.storage.microSD128GB"
                                            checked={this.state.selectedStorage === "dedicated.storage.microSD128GB"}
                                            onChange={this.handleChange}
                                        />
                                        {t('dedicated.storage.microSD128GB')}
                                        </label>
                                </div>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            name="selectedStorage"
                                            value="dedicated.storage.microSD256GB"
                                            checked={this.state.selectedStorage === "dedicated.storage.microSD256GB"}
                                            onChange={this.handleChange}
                                        />
                                        {t('dedicated.storage.microSD256GB')}
                                        </label>
                                </div>
                                <div>
                                    <label>
                                        <input
                                            type="radio"
                                            name="selectedStorage"
                                            value="dedicated.storage.nfs"
                                            checked={this.state.selectedStorage === "dedicated.storage.nfs"}
                                            onChange={this.handleChange}
                                        />
                                        {t('dedicated.storage.nfs')}
                                        </label>
                                </div>

                                <br />
                                { !this.state.showNFS &&
                                   
                                <span>                 
                                <hr className="vps-hr" />
                                    <div className="vps-item">
                                        <SliderComponentDisk  onChangeSliderValDisk={this.setSliderValDisk} />
                                        
                                    </div>
                                </span>
                                }
                                <hr className="vps-hr" />
                                <br />
                                <div className="vps-item">
                                    <label>
                                        <div className="vps-item-title">{t('common.network.IPv4Count')}</div>
                                        <select
                                            name="steviloJavnihIP"
                                            className="order-input"
                                            onChange={this.handleChange}
                                            value={this.state.technology}>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                        </select>
                                    </label>
                                    <br />{t('common.network.description')}
                                    <br />{t('common.network.IPv6Included')}
                                </div>
                                <hr className="vps-hr" />
                                <br />
                                <div className="vps-item">
                                    <label>
                                        <div className="vps-item-title">{t('common.os.name')}</div>
                                        <select
                                            name="os"
                                            className="order-input"
                                            onChange={this.handleChange}
                                        >
                                            <option value="Ubuntu Server 20.04 LTS">Ubuntu Server 20.04 LTS</option>
                                        </select>
                                    </label>
                                </div>
                                <hr className="vps-hr" />
                                <br />
                                <div className="vps-item">
                                    <label>
                                        <div className="vps-item-title">{t('common.finance.subscription.period')}</div>
                                        <select
                                            name="obdobjeNajema"
                                            className="order-input"
                                            onChange={this.handleChange}
                                        >
                                            <option value="common.finance.subscription.monthlyNoDiscount">{t('common.finance.subscription.monthlyNoDiscount')}</option>
                                            <option value="common.finance.subscription.yearlyDiscount">{t('common.finance.subscription.yearlyDiscount')}</option>
                                        </select>
                                    </label>
                                </div>
                                <hr className="vps-hr" />
                                <br />
                                <div className="vps-item">
                                    {t('common.network.freeInputTraffic')}
                                    <br />{t('common.network.freeInternalTraffic')}
                                    <br />{t('common.network.includedTraffic')}
                                </div>
                            </div>
                            <DedicatedCalc className="calc-div" {... this.state} />
                        </div>

                    </div>
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="middle-subtitle middle-1">{t('dedicated.priceList')}</div>
                    <br />
                    <div className="vps-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>{t('common.priceList.common.service')}</th>
                                    <th>{t('common.finance.subscription.monthly')}</th>
                                    <th>{t('common.finance.subscription.setup')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{t('dedicated.server.rPi4-2')}</td>
                                    <td>5,18 €</td>
                                    <td>5,18 €</td>
                                </tr>
                                <tr>
                                    <td>{t('dedicated.server.rPi4-4')}</td>
                                    <td>5,64 €</td>
                                    <td>5,64 €</td>
                                </tr>
                                <tr>
                                    <td>{t('dedicated.server.rPi4-8')}</td>
                                    <td>6,91 €</td>
                                    <td>6,91 €</td>
                                </tr>
                                <tr>
                                    <td>{t('dedicated.storage.microSD32GB')}</td>
                                    <td>0,72 €</td>
                                    <td>0,72 €</td>
                                </tr>
                                <tr>
                                    <td>{t('dedicated.storage.microSD128GB')}</td>
                                    <td>1,33 €</td>
                                    <td>1,33 €</td>
                                </tr>
                                <tr>
                                    <td>{t('dedicated.storage.microSD256GB')}</td>
                                    <td>2,16 €</td>
                                    <td>2,16 €</td>
                                </tr>
                                <tr>
                                    <td>{t('dedicated.storage.nfsPrice')}</td>
                                    <td>0,02 €</td>
                                    <td>{t('common.priceList.common.free')}</td>
                                </tr>
                                <tr>
                                    <td>{t('common.priceList.common.networkOutputTraffic')}</td>
                                    <td>0,98 €</td>
                                    <td>{t('common.priceList.common.free')}</td>
                                </tr>
                                <tr>
                                    <td>{t('common.priceList.common.networkIPv4')}</td>
                                    <td>0,98 €</td>
                                    <td>{t('common.priceList.common.free')}</td>
                                </tr>
                                <tr>
                                    <td>{t('common.priceList.common.networkIPv6')}</td>
                                    <td>0,32 €</td>
                                    <td>{t('common.priceList.common.free')}</td>
                                </tr>
                                <tr>
                                    <td>{t('common.priceList.common.backupBox')}</td>
                                    <td><a href="/backup-box">{t('common.priceList.common.backupBoxLink')}</a></td>
                                    <td><a href="/backup-box">{t('common.priceList.common.backupBoxLink')}</a></td>
                                </tr>
                                <tr>
                                    <td>{t('common.priceList.common.individualOffer')}</td>
                                    <td>-</td>
                                    <td>23,64 €</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br /><br />
                </div>
            </>

        )
    }
}
const Dedicated = withTranslation()(DedicatedT);

export default Dedicated