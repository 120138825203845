import React from 'react';
import './css/order-loader.scss'


const Loader = (props) => (
    <div className="loader-overlay">
        <div className={props.className}>
            <div className="loader-text">
                Pošiljanje je v teku...
        </div>
            <div className="container">
                <div className="item-1"></div>
                <div className="item-2"></div>
                <div className="item-3"></div>
                <div className="item-4"></div>
                <div className="item-5"></div>
            </div>
        </div>
    </div>
);

export default Loader