import React from "react";
import "./../../css/style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
//import LazyLoad from 'react-lazy-load';

import { withTranslation } from "react-i18next";

import { FaAlignJustify } from "react-icons/fa";

import "./../../css/main-menu.scss";
import { PRODUCT_TYPES } from "./../../const/products";

import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import logo1 from "./../../assets/icons/Synology_logo_black.svg";
import logo2 from "./../../assets/icons/Qnap_logo_black.svg";
import logo3 from "./../../assets/icons/icon_backup_box1.png";

class MainMenuT extends React.Component {
  constructor(props) {
    super(props);

    // withTranslation.t('about.title')
    //this.props.t('about.title');
    /*     i18next.t('about.title') */
    // alert("111, ",initReactI18next.t('about.title'))
    this.state = {
      show: false,
      displayMenu: false,
      showProduct: false,
      redirectToProducts: false,
      pathname: "",

      product1: {
        productName: "Varnostno kopiranje ",
        productId: 1,
        productList: [
          {
            id: 4,
            key: "syn",
            type: PRODUCT_TYPES.SYNOLOGY_BACKUP,
            name: "Synology backup",
            link: "/synology-backup",
            img_url: logo1,
          },
          {
            id: 5,
            key: "qnap",
            type: PRODUCT_TYPES.QNAP_BACKUP,
            name: "QNAP Backup",
            link: "/qnap-backup",
            img_url: logo2,
          },
          {
            id: 6,
            type: PRODUCT_TYPES.BACKUP_BOX,
            key: "box",
            name: "Backup Box",
            link: "/backup-box",
            img_url: logo3,
          },
        ],
      },
      selectedProduct: {},
      selectedMenuItem: 1,
      displayResponsiveMenu: false,
      displaySubmenu: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.displayMenuBoxPanel = this.displayMenuBoxPanel.bind(this);
    this.hideMenuBoxPanel = this.hideMenuBoxPanel.bind(this);
  }

  toggleMenu() {
    this.setState({
      show: !this.state.show,
      displayResponsiveMenu: true,
    });
  }

  displayMenuBoxPanel(event, id) {
    event.preventDefault();

    let productName = event.target.name;

    this.refs.products.style.background =
      "linear-gradient(to bottom, #8aa9ff 1px, #fffefe 3px)";
    this.refs.products.style.color = "#292929";

    if (productName) {
      this.setState({
        displayMenu: true,
        selectedProduct: this.state[productName],
      });
    }
  }
  setMenuItemSelected = (e) => {
    let selectedId = e.target.id;
    switch (selectedId) {
      case 4:
        selectedId = 1;
        break;
      case 5:
        selectedId = 1;
        break;
      case 6:
        selectedId = 1;
        break;
      default:
        selectedId = e.target.id;
    }
    this.setState({
      selectedMenuItem: selectedId,
    });

    if (selectedId === 1) {
      this.setState({
        displaySubmenu: true,
        show: true,
      });
    } else if (
      Number(selectedId) === 5 ||
      Number(selectedId) === 6 ||
      Number(selectedId) === 4
    ) {
      this.setState({
        displaySubmenu: false,
        displayResponsiveMenu: false,
        show: false,
      });
    } else {
      this.setState({
        displaySubmenu: false,
        displayResponsiveMenu: false,
        show: false,
      });
    }
  };

  hideMenuBoxPanel(event) {
    this.setState({
      displayMenu: false,
    });
    this.refs.products.style.background = "#292929";
    this.refs.products.style.color = "white";
  }
  render() {
    const { t } = this.props;
    return [
      <div className="a" key="a">
        <div className="main-menu-top" key="main-menu-top">
          <div onClick={this.toggleMenu}>
            <FaAlignJustify className="burger-menu-icon" />
          </div>

          <div
            className={
              "main-menu-links " +
              (this.state.show && this.state.displayResponsiveMenu
                ? "toggleMenu"
                : "")
            }
          >
            <Link
              id="0"
              className={
                "main-menu-link " +
                (this.state.selectedMenuItem === 0 ? "selected" : "")
              }
              ref="0"
              key="0"
              onMouseOver={this.hideMenuBoxPanel}
              to="/about"
              onClick={this.setMenuItemSelected}
            >
              {/* O NAS */}
              {t("about.menu")}
            </Link>

            <Link
              id="1"
              to="backup"
              ref="products"
              key="1"
              className={
                "main-menu-link " +
                (this.state.selectedMenuItem === 1 ? "selected" : "")
              }
              onClick={this.setMenuItemSelected}
              onMouseOver={this.displayMenuBoxPanel}
              name="product1"
            >
              {" "}
              {t("backup.title")} <FontAwesomeIcon icon={faAngleDown} />
            </Link>
            <div
              key="main-menu-submenu"
              className={
                "main-menu-submenu " +
                (this.state.displaySubmenu === true ? "submenu-show" : "")
              }
            >
              <Link
                id="4"
                key="synology1"
                className="main-menu-link submenu"
                onClick={this.setMenuItemSelected}
                to="/synology-backup"
              >
                {" "}
                {t("synology-backup.title")}
              </Link>
              <Link
                id="5"
                key="qnap1"
                className="main-menu-link submenu"
                onClick={this.setMenuItemSelected}
                to="/qnap-backup"
              >
                {" "}
                {t("qnap-backup.title")}
              </Link>
              <Link
                id="6"
                key="backup1"
                className="main-menu-link submenu"
                onClick={this.setMenuItemSelected}
                to="/backup-box"
              >
                {" "}
                {t("backup-box.title")}
              </Link>
            </div>
            <Link
              id="2"
              key="2"
              ref="2"
              className={
                "main-menu-link vps" +
                (this.state.selectedMenuItem === 2 ? "selected" : "")
              }
              onMouseOver={this.hideMenuBoxPanel}
              onClick={this.setMenuItemSelected}
              to="/vps"
            >
              {t("vps.menu")}
            </Link>
            <Link
              id="4"
              key="8"
              ref="4"
              className={
                "main-menu-link " +
                (this.state.selectedMenuItem === 4 ? "selected" : "")
              }
              onMouseOver={this.hideMenuBoxPanel}
              to="/lxc"
              onClick={this.setMenuItemSelected}
            >
              {t("lxc.menu")}
            </Link>
            <Link
              id="3"
              key="3"
              ref="3"
              className={
                "main-menu-link " +
                (this.state.selectedMenuItem === 3 ? "selected" : "")
              }
              onMouseOver={this.hideMenuBoxPanel}
              to="/colocation"
              onClick={this.setMenuItemSelected}
            >
              {t("colocation.menu")}
            </Link>
            <Link
              id="7"
              key="7"
              ref="7"
              className={
                "main-menu-link " +
                (this.state.selectedMenuItem === 7 ? "selected" : "")
              }
              onMouseOver={this.hideMenuBoxPanel}
              to="/dedicated"
              onClick={this.setMenuItemSelected}
            >
              {t("dedicated.menu")}
            </Link>
          </div>
        </div>

        {this.state.displayMenu && (
          <div className="main-menu-panel">
            <div
              className="main-menu-products"
              onMouseEnter={this.displayMenuBoxPanel}
              onMouseLeave={this.hideMenuBoxPanel}
            >
              {this.state.selectedProduct.productList.map((product) => (
                <Link
                  /*                   key={product.key}
                   */ className="main-menu-product-box"
                  id={product.type}
                  to={product.link}
                  onClick={this.selectedMenuItem}
                >
                  <p
                    key={product.id}
                    id={product.id}
                    className="main-menu-product-desc"
                  >
                    {product.name}
                  </p>
                  {/* <LazyLoad
                                            debounce={false}
                                        > */}
                  <img
                    key={product.id}
                    id={product.id}
                    className={"main-menu-img " + product.name}
                    src={product.img_url}
                    alt=""
                  />
                  {/*                                         </LazyLoad>
                   */}{" "}
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>,
    ];
  }
}

const MainMenu = withTranslation()(MainMenuT);

export default MainMenu;
