import React, { Component } from "react";
import logo1 from "./../../assets/icons/Synology_logo_black.svg";
import "./../../css/product.scss";
import ProducDetailPanel1 from "../ProductDetailPanel1";
import ProducDetailPanel2 from "../ProductDetailPanel2";
import { withTranslation } from "react-i18next";

import { PRODUCT_TYPES } from "./../../const/products";

let productDataSynology = {
  calcType: PRODUCT_TYPES.SYNOLOGY_BACKUP,
  title: "backup.synology.title",
  description: "backup.synology.text1",
  backgroundImage: "../assets/img/g1.png",
  logo: logo1,
  productDescriptionBottom:
    "V kolikor želite večjo količino, nam pišite na naslov ",
  tablePrice1: "0,00 €",
  tablePrice2: "1,10 € / ",
  tablePrice3: "4,19 € / ",
};

class ProductSynologyT extends Component {
  render() {
    return (
      <>
        <ProducDetailPanel1 data={productDataSynology} />
        <ProducDetailPanel2 data={productDataSynology} />
      </>
    );
  }
}
const ProductSynology = withTranslation()(ProductSynologyT);

export default ProductSynology;
